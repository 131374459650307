import React from 'react';

import { Container } from './style';

import interation from '../../assets/interation.svg';
import client from '../../assets/client.svg';
import security from '../../assets/security.svg';
import api from '../../assets/api.svg';

function GoToMarketSection() {
  return (
    <Container>
      <div className="container">
        <div className="grid-info">
          <div className="go-to-market-info">
            <h2>Rapidez no Go to Market</h2>

            <h3>
              Fácil, rápido e prático. Com o Microbank você tem a segurança de
              um go to market rápido, sem se preocupar em perder tempo com a
              implantação.
            </h3>
            <p>
              Tudo isso é possível através da integração da camada de API’s que
              a plataforma do Microbank oferece: é só plugar as customizações
              específicas do seu modelo de negócio e pronto, sua solução está
              pronta para o mercado.
              <span>
                É possível que seu projeto esteja no mercado em poucas semanas.
              </span>
            </p>
          </div>

          <div className="go-to-market-items">
            <h3>Vantagens da plataforma Microbank</h3>

            <div className="grid-items">
              <div className="item">
                <div className="image">
                  <img src={interation} alt="" />
                </div>
                <p>Integração fácil e intuitiva</p>
              </div>

              <div className="item">
                <div className="image">
                  <img src={client} alt="" />
                </div>
                <p>Comportamento do cliente</p>
              </div>

              <div className="item">
                <div className="image">
                  <img src={security} alt="" />
                </div>
                <p>Segurança e Normalização</p>
              </div>

              <div className="item">
                <div className="image">
                  <img src={api} alt="" />
                </div>
                <p>APIs Robustas</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default GoToMarketSection;
