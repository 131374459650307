import * as React from 'react';

function IconMicrobank(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={202.052}
      height={239.069}
      {...props}
    >
      <g data-name="Grupo 12170" opacity={0.1}>
        <path
          data-name="Caminho 48694"
          d="M34.257 194.596L7.205 39.58h14.968l25.209 144.445 70.875 30.355 60.28-30.355-25.208-144.445h14.959l27.052 155.015-73.726 39.021zm94.977-123.388l-22.709 75.988-49.231-75.988 19.144 109.7-16.183-6.91-23.46-134.433h17.258l46.099 71.11 21.276-71.11h17.252l23.46 134.43-13.762 6.912zM4.964 26.74L.372.43h15.071l2.18 12.489h56.426L71.869.43h18.088l2.18 12.489h56.43L146.389.43h15.065l4.592 26.31z"
          fill="#d0d5ff"
        />
        <path
          data-name="Subtra\xE7\xE3o 30"
          d="M121.61 233.619v-.007l-3.357-19.23 60.287-30.356L153.33 39.582h14.963l27.053 155.014zm26.767-52.708L129.232 71.209l-22.705 75.979-6.374-36.52 21.277-71.103h17.252l23.46 134.431-13.756 6.912zm17.676-154.17H85.507L80.915.43h9.046l2.18 12.489h56.432L146.393.43h15.068l4.592 26.31z"
          fill="#92a1fd"
        />
      </g>
    </svg>
  );
}

export default IconMicrobank;
