import * as React from 'react';

function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50.225"
      height="49.998"
      viewBox="0 0 50.225 49.998"
    >
      <defs>
        <filter
          id="Caminho_48698"
          x="0"
          y="0"
          width="50.225"
          height="49.998"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood floodColor="#e0beff" floodOpacity="0.502" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Caminho_48698)">
        <path
          id="Caminho_48698-2"
          data-name="Caminho 48698"
          d="M22330.047,22318.926a14.7,14.7,0,0,1-4.869-.932,9.938,9.938,0,0,1-3.547-2.316,9.774,9.774,0,0,1-2.312-3.559,14.872,14.872,0,0,1-.936-4.869c-.1-2.172-.1-14.4,0-16.564a14.7,14.7,0,0,1,.936-4.871,9.664,9.664,0,0,1,2.313-3.562,9.945,9.945,0,0,1,3.547-2.318,15.116,15.116,0,0,1,4.869-.932h16.525a15.056,15.056,0,0,1,4.871.932,10.246,10.246,0,0,1,5.863,5.881,14.928,14.928,0,0,1,.932,4.871c.1,2.166.1,14.393,0,16.564a15.031,15.031,0,0,1-.932,4.869,9.85,9.85,0,0,1-2.311,3.559,9.988,9.988,0,0,1-3.553,2.316,14.689,14.689,0,0,1-4.871.932c-1.156.057-5.027.074-8.26.074C22334.256,22319,22331.008,22318.971,22330.047,22318.926Zm-1.711-20.346a9.522,9.522,0,1,0,9.523-9.539A9.539,9.539,0,0,0,22328.336,22298.58Zm18.271-10.336a2.4,2.4,0,1,0,2.406-2.406A2.41,2.41,0,0,0,22346.607,22288.244Zm-15.264,10.336a6.515,6.515,0,1,1,6.516,6.531A6.523,6.523,0,0,1,22331.344,22298.58Z"
          transform="translate(-22313.19 -22274)"
          fill="none"
          stroke="#92a1fd"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default Instagram;
