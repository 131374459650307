import React from 'react';
import { Container } from './style';

import illustrationFutureOfMicrobanks from '../../assets/future-of-microbanks.svg';

function FutureOfMicrobanks() {
  return (
    <Container>
      <div className="grid container">
        <div className="image-future-of-microbanks">
          <img src={illustrationFutureOfMicrobanks} alt="" />
        </div>

        <div className="info-future-of-microbanks">
          <div>
            <p>Por que o futuro é dos microbancos!</p>
            <p>
              E você já pode ter o seu, customizado de acordo com o segmento do
              seu negócio.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default FutureOfMicrobanks;
