import styled from 'styled-components';
import bgWhyHaveBank from '../../assets/bg-why-have-bank.svg';

export const Container = styled.div`
  padding: 200px 0;
  background: url(${bgWhyHaveBank});
  background-repeat: no-repeat;
  background-position: top right;
  display: grid;
  grid-template-columns: 1fr 3fr;

  h3 {
    color: #040242;
    font-size: 2em;
    margin-bottom: 40px;
  }

  #img-mobile {
    display: none;
  }

  .item {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 100px 3fr;
    align-items: center;
  }

  .img {
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(72, 13, 126, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    padding: 10px;
  }

  p {
    color: #480d7e;
    font-size: 13px;
    font-weight: 500;
    line-height: 23px;
    margin-left: 20px;
  }

  @media screen and (max-width: 1000px) {
    padding: 100px 0;
    background: #ffffff;
    display: grid;
    grid-template-columns: 1fr 2fr;

    h3 {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 100px 0;
    grid-template-columns: 1fr;
    position: relative;

    h3 {
      font-size: 18px;
      line-height: 26px;
      width: 60%;
      text-align: right;
      float: right;
      margin-right: 10px;
    }

    #img-mobile {
      display: block;
      position: absolute;
      top: 70px;
      left: 10px;
      width: 45%;
    }

    .block-items {
      margin: 170px 10px 0;
      padding: 20px;
      box-shadow: 0px 3px 6px rgba(72, 13, 126, 0.3);
    }

    .img {
      width: 70px;
      height: 70px;
    }

    .img img {
      width: 50px;
      height: 50px;
    }

    p {
      font-size: 11px;
      line-height: 20px;
      margin-left: 10px;
    }
  }
`;
