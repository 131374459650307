import styled from 'styled-components';

export const Container = styled.div`
  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    /* Remove aquele fundo azul quando o objeto é clicado */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .menu {
    background: transparent;
    border-radius: 50%;
    top: 15px;
    box-shadow: 0 0 0 0 #fff, 0 0 0 0 #fff;
    cursor: pointer;
    height: 50px;
    position: fixed;
    right: 15px;
    transition: box-shadow 1.1s cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 100;
    display: none;
  }

  .hamburger {
    position: relative;
    top: 0px;
    left: 12px;
    width: 25px;
    height: 3px;
    background: #fff;
    display: block;
    transform-origin: center;
    transition: 0.5s ease-in-out;
  }

  .hamburger:after,
  .hamburger:before {
    background: #fff;
    content: '';
    display: block;
    transition: 0.5s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .hamburger:before {
    top: -10px;
  }

  .hamburger:after {
    bottom: -10px;
  }

  input {
    display: none;
  }

  input:checked ~ label .menu {
    box-shadow: 0 0 0 130vw #391463, 0 0 0 130vh #391463;
  }

  input:checked ~ label .hamburger {
    transform: rotate(45deg);
  }

  input:checked ~ label .hamburger:after {
    transform: rotate(90deg);
    bottom: 0;
  }

  input:checked ~ label .hamburger:before {
    transform: rotate(90deg);
    top: 0;
  }

  input:checked ~ ul,
  input:checked ~ div.social-media {
    opacity: 1;
    visibility: visible;
  }

  div.social-media {
    position: fixed;
    top: 45%;
    left: 50px;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 101;
  }

  ul {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    opacity: 0;
    transition: 0.25s 0.1s cubic-bezier(0, 1.07, 0, 1.02);
    visibility: hidden;
    z-index: 101;
    position: fixed;
    width: 100%;
  }

  a {
    color: #fff;
    display: block;
    margin-bottom: 70px;
    text-decoration: none;
    margin-right: 20px;
    font-size: 18px;
    text-align: right;
  }

  .contact {
    color: #ff9d00;
    font-weight: 600;
  }

  @media screen and (max-width: 1000px) {
    .menu {
      display: block;
    }
  }

  @media (max-width: 450px) {
    .social-media {
      margin-top: 10px;
    }

    ul {
      margin-top: 80px;
    }

    a {
      margin-bottom: 50px;
    }
  }
`;
