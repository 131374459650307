import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { Content, Container, Label } from './style';

export default function Input({ name, label, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <Content>
      <Label htmlFor={fieldName}>{label}</Label>
      <Container
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </Content>
  );
}
