import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 100px 0;
  grid-gap: 20px;

  p {
    color: #52526a;
    font-size: 1.2em;
    margin-bottom: 25px;
    line-height: 30px;
  }

  span {
    font-weight: 500;
  }

  .purple {
    color: #480d7e;
    font-size: 1.4em;
    font-weight: 600;
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const ContainerTablet = styled.div`
  display: none;
  margin-bottom: 50px;

  p {
    color: #52526a;
    font-size: 12px;
    margin-bottom: 20px;
    line-height: 20px;
    padding: 0 10px;
  }

  span {
    font-weight: 500;
  }

  .purple {
    color: #480d7e;
    font-size: 1.4em;
    font-weight: 600;
  }

  @media screen and (max-width: 1000px) {
    display: block;
  }

  @media screen and (max-width: 768px) {
    p {
      color: #52526a;
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 20px;
      line-height: 22px;
      padding: 0 10px;
    }
    span {
      color: #480d7e;
    }
  }
`;
