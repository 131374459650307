import * as React from 'react';

function IconHexalab(props) {
  return (
    <svg
      data-name="Grupo 12090"
      xmlns="http://www.w3.org/2000/svg"
      width={102.732}
      height={43.769}
      {...props}
    >
      <path
        data-name="Caminho 25091"
        d="M2.15 0h4.993v6.523a12.682 12.682 0 01-.058 1.27h.058a5.1 5.1 0 014.531-2.366c3 0 5.34 1.385 5.34 5.57v9.639h-4.993V11.92c0-1.385-.548-1.963-1.645-1.963a3 3 0 00-2.973 2.194 5.74 5.74 0 00-.26 1.9v6.58H2.15z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25092"
        d="M24.284 5.426c4.387 0 6.869 3.059 6.869 7.186 0 .491-.115 1.559-.115 1.559h-9.38a3.318 3.318 0 003.463 2.655 7.794 7.794 0 004.1-1.5l1.847 3.492a10.039 10.039 0 01-6.35 2.164c-5.31 0-8.168-3.839-8.168-7.793a7.4 7.4 0 017.735-7.764m1.848 5.888a2.024 2.024 0 00-1.963-2.136 2.437 2.437 0 00-2.4 2.136z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25093"
        d="M34.241 13.277l-4.791-7.5h5.628l1.616 3.32c.2.375.461.981.461.981h.058s.288-.606.462-.981l1.617-3.32h5.628l-4.762 7.5 4.675 7.36H39.35l-1.79-3.348a9.62 9.62 0 01-.346-.751h-.058s-.2.491-.346.751l-1.761 3.348h-5.483z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25094"
        d="M51.701 11.372h.2v-.26c0-1.242-.953-1.645-2.194-1.645a8.874 8.874 0 00-4.012 1.414L43.964 7.36a11.242 11.242 0 016.234-1.934c4.185 0 6.7 2.28 6.7 6.061v9.149h-4.561v-.9a7.791 7.791 0 01.058-.923h-.058a4.625 4.625 0 01-4.214 2.165 4.754 4.754 0 01-5.05-4.791c0-4.329 6.321-4.819 8.63-4.819m-2.193 5.8a2.757 2.757 0 002.453-2.626v-.318h-.462c-1.587 0-3.406.462-3.406 1.7 0 .693.433 1.241 1.414 1.241"
        fill="#fff"
      />
      <path
        data-name="Caminho 25095"
        d="M62.341 16.137l-1.51-2.615 1.51-2.616h3.02l1.51 2.616-1.51 2.615z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25096"
        d="M1.876 28.096a.577.577 0 00-.635-.635H0v-4.329h4.934c1.471 0 2.107.635 2.107 2.107v13.565a.578.578 0 00.635.635h2.713a.577.577 0 00.634-.635v-1.125h4.618v3.983c0 1.472-.635 2.107-2.107 2.107H3.981c-1.472 0-2.107-.635-2.107-2.107z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25097"
        d="M13.621 39.439h.549c.462 0 .605-.144.779-.635l5.628-15.672h5.368l5.628 15.672c.173.491.318.635.779.635h.549v4.329h-3.32a2.1 2.1 0 01-2.425-1.674l-.895-2.655h-6l-.894 2.655a2.1 2.1 0 01-2.425 1.674h-3.321zm11.544-4.185l-1.039-3.434c-.4-1.3-.837-3.406-.837-3.406h-.058s-.433 2.107-.838 3.406l-1.039 3.434z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25098"
        d="M32.696 27.461H30.82v-4.329h9.033c4.127 0 7.014 1.847 7.014 5.4a4.949 4.949 0 01-2.4 4.387v.058a4.627 4.627 0 013.233 4.647c0 4.5-3.752 6.147-7.851 6.147h-5.05c-1.472 0-2.107-.635-2.107-2.107zm7.215 3.665a1.732 1.732 0 001.761-1.9 1.66 1.66 0 00-1.847-1.76h-1.963v3.665zm-1.414 8.312h1.847a1.982 1.982 0 002.049-2.165 1.915 1.915 0 00-2.021-2.107h-2.51v3.636a.577.577 0 00.635.635"
        fill="#fff"
      />
      <path
        data-name="Caminho 25099"
        d="M52.658 22.984h4.328v1.132h-3.075v1.225h2.71v1.127h-2.71v2.2h-1.249z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25100"
        d="M60.74 28.761a3.094 3.094 0 01-1.209-.231 3 3 0 01-.953-.625 2.791 2.791 0 01-.625-.926 2.914 2.914 0 01-.223-1.14v-.017a2.87 2.87 0 01.227-1.14 2.907 2.907 0 011.586-1.566 3.288 3.288 0 012.422 0 3 3 0 01.954.625 2.8 2.8 0 01.625.925 2.907 2.907 0 01.223 1.14v.016a2.868 2.868 0 01-.227 1.14 2.9 2.9 0 01-1.586 1.566 3.123 3.123 0 01-1.214.233zm.016-1.152a1.707 1.707 0 00.69-.138 1.578 1.578 0 00.54-.381 1.787 1.787 0 00.349-.564 1.857 1.857 0 00.126-.686v-.017a1.9 1.9 0 00-.126-.69 1.7 1.7 0 00-.9-.953 1.661 1.661 0 00-.69-.142 1.7 1.7 0 00-.693.138 1.574 1.574 0 00-.535.381 1.791 1.791 0 00-.349.564 1.859 1.859 0 00-.126.686v.016a1.887 1.887 0 00.126.69 1.71 1.71 0 00.9.954 1.653 1.653 0 00.688.142z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25101"
        d="M64.88 22.984h2.6a2.24 2.24 0 011.655.576 1.753 1.753 0 01.487 1.3v.016A1.78 1.78 0 0169.285 26a1.947 1.947 0 01-.881.637l1.388 2.029h-1.461l-1.217-1.818h-.982v1.818H64.88zm2.516 2.759a1.037 1.037 0 00.709-.219.741.741 0 00.248-.584v-.017a.724.724 0 00-.259-.608 1.148 1.148 0 00-.722-.2H66.13v1.631z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25102"
        d="M74.778 26.425l-2.183-3.441h1.461l1.355 2.28 1.38-2.28h1.42L76.028 26.4v2.264h-1.25z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25103"
        d="M81.748 28.761a3.1 3.1 0 01-1.209-.231 3 3 0 01-.953-.625 2.8 2.8 0 01-.625-.926 2.914 2.914 0 01-.223-1.14v-.017a2.862 2.862 0 01.228-1.14 2.905 2.905 0 011.586-1.566 3.288 3.288 0 012.422 0 3 3 0 01.954.625 2.8 2.8 0 01.625.925 2.9 2.9 0 01.223 1.14v.016a2.874 2.874 0 01-.227 1.14 2.907 2.907 0 01-1.586 1.566 3.126 3.126 0 01-1.215.233zm.016-1.152a1.711 1.711 0 00.69-.138 1.582 1.582 0 00.54-.381 1.781 1.781 0 00.349-.564 1.858 1.858 0 00.126-.686v-.017a1.9 1.9 0 00-.126-.69 1.7 1.7 0 00-.9-.953 1.663 1.663 0 00-.69-.142 1.7 1.7 0 00-.694.138 1.578 1.578 0 00-.535.381 1.8 1.8 0 00-.349.564 1.859 1.859 0 00-.126.686v.016a1.887 1.887 0 00.126.69 1.713 1.713 0 00.357.568 1.734 1.734 0 00.544.385 1.657 1.657 0 00.688.143z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25104"
        d="M88.256 28.753a2.252 2.252 0 01-2.459-2.523v-3.246h1.249v3.213a1.5 1.5 0 00.325 1.051 1.161 1.161 0 00.9.352 1.185 1.185 0 00.9-.34 1.433 1.433 0 00.325-1.022v-3.254h1.249v3.205a3.3 3.3 0 01-.174 1.128 2.128 2.128 0 01-.5.8 2.044 2.044 0 01-.787.479 3.174 3.174 0 01-1.028.157z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25105"
        d="M92.021 22.984h2.6a2.24 2.24 0 011.655.576 1.752 1.752 0 01.487 1.3v.016A1.78 1.78 0 0196.426 26a1.947 1.947 0 01-.881.637l1.388 2.029h-1.461l-1.217-1.818h-.982v1.818h-1.249zm2.516 2.759a1.037 1.037 0 00.71-.219.741.741 0 00.247-.584v-.017a.724.724 0 00-.264-.607 1.148 1.148 0 00-.722-.2H93.27v1.631z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25106"
        d="M52.658 30.538h2.215a3.356 3.356 0 011.221.215 2.883 2.883 0 01.957.6 2.653 2.653 0 01.621.9 2.82 2.82 0 01.219 1.116v.016a2.852 2.852 0 01-.219 1.12 2.633 2.633 0 01-.621.9 2.928 2.928 0 01-.957.6 3.3 3.3 0 01-1.221.219h-2.215zm2.215 4.552a1.943 1.943 0 00.7-.121 1.511 1.511 0 00.54-.349 1.653 1.653 0 00.349-.535 1.822 1.822 0 00.125-.69v-.017a1.863 1.863 0 00-.125-.69 1.637 1.637 0 00-.349-.544 1.555 1.555 0 00-.54-.352 1.874 1.874 0 00-.7-.126h-.965v3.424z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25107"
        d="M59.06 30.538h1.249v5.68H59.06z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25108"
        d="M63.763 36.3a3.766 3.766 0 01-1.274-.223 3.391 3.391 0 01-1.136-.686l.738-.884a3.655 3.655 0 00.8.5 2.133 2.133 0 00.9.187 1.09 1.09 0 00.6-.142.447.447 0 00.215-.394v-.016a.492.492 0 00-.045-.215.453.453 0 00-.171-.175 1.822 1.822 0 00-.35-.162q-.224-.081-.581-.171a6.983 6.983 0 01-.781-.235 2.208 2.208 0 01-.594-.321 1.292 1.292 0 01-.378-.475 1.648 1.648 0 01-.135-.707v-.016a1.651 1.651 0 01.145-.7 1.6 1.6 0 01.407-.54 1.816 1.816 0 01.626-.349 2.507 2.507 0 01.8-.122 3.392 3.392 0 011.148.187 3.336 3.336 0 01.962.536l-.649.941a4.15 4.15 0 00-.747-.41 1.911 1.911 0 00-.73-.15.886.886 0 00-.548.142.433.433 0 00-.183.353v.016a.5.5 0 00.053.239.474.474 0 00.191.183 1.819 1.819 0 00.378.154q.24.073.606.17a5.812 5.812 0 01.769.256 2.136 2.136 0 01.569.341 1.293 1.293 0 01.35.467 1.585 1.585 0 01.118.641v.016a1.757 1.757 0 01-.153.751 1.538 1.538 0 01-.427.552 1.919 1.919 0 01-.654.341 2.842 2.842 0 01-.839.12z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25109"
        d="M66.923 30.538h2.6a2.242 2.242 0 011.655.576 1.753 1.753 0 01.487 1.3v.016a1.78 1.78 0 01-.337 1.124 1.95 1.95 0 01-.881.637l1.388 2.029h-1.464l-1.217-1.818h-.982v1.818h-1.249zm2.516 2.759a1.037 1.037 0 00.709-.219.742.742 0 00.248-.584v-.016a.724.724 0 00-.259-.608 1.148 1.148 0 00-.722-.2h-1.242v1.631z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25110"
        d="M75.159 36.307a2.252 2.252 0 01-2.459-2.524v-3.246h1.25v3.213a1.5 1.5 0 00.325 1.051 1.161 1.161 0 00.9.353 1.187 1.187 0 00.9-.34 1.433 1.433 0 00.325-1.023v-3.254h1.249v3.205a3.31 3.31 0 01-.174 1.128 2.134 2.134 0 01-.5.8 2.036 2.036 0 01-.787.479 3.16 3.16 0 01-1.029.158z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25111"
        d="M78.925 30.538h2.323a2.734 2.734 0 01.913.142 1.931 1.931 0 01.682.4 1.758 1.758 0 01.43.621 2.067 2.067 0 01.15.8v.016a1.992 1.992 0 01-.178.868 1.777 1.777 0 01-.491.624 2.135 2.135 0 01-.73.377 3.082 3.082 0 01-.9.126h-.949v1.7h-1.249zm2.239 2.864a1.016 1.016 0 00.731-.248.812.812 0 00.26-.613v-.016a.776.776 0 00-.272-.641 1.153 1.153 0 00-.743-.219h-.965v1.737z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25112"
        d="M85.667 31.69h-1.728v-1.152h4.706v1.152h-1.728v4.528h-1.25z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25113"
        d="M89.66 30.538h1.249v5.68H89.66z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25114"
        d="M91.843 30.538h1.38l1.485 4 1.485-4h1.347l-2.3 5.721h-1.1z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25115"
        d="M98.416 30.538h4.276v1.111h-3.043v1.153h2.678v1.111h-2.678v1.193h3.083v1.112h-4.317z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25116"
        d="M52.658 38.007h1.249v5.68h-1.249z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25117"
        d="M55.328 38.007h2.215a3.358 3.358 0 011.221.215 2.886 2.886 0 01.957.6 2.656 2.656 0 01.621.9 2.819 2.819 0 01.219 1.116v.016a2.847 2.847 0 01-.219 1.12 2.633 2.633 0 01-.621.9 2.93 2.93 0 01-.957.6 3.307 3.307 0 01-1.221.219h-2.215zm2.215 4.552a1.945 1.945 0 00.7-.121 1.513 1.513 0 00.539-.349 1.645 1.645 0 00.349-.535 1.814 1.814 0 00.126-.69v-.017a1.854 1.854 0 00-.126-.69 1.63 1.63 0 00-.349-.544 1.554 1.554 0 00-.539-.352 1.875 1.875 0 00-.7-.126h-.966v3.424z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25118"
        d="M61.673 38.007h4.276v1.111h-3.043v1.153h2.678v1.111h-2.678v1.192h3.084v1.112h-4.317z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25119"
        d="M69.057 37.966h1.152l2.434 5.721h-1.306l-.519-1.274h-2.4l-.519 1.274h-1.274zm1.315 3.343l-.755-1.842-.754 1.842z"
        fill="#fff"
      />
      <path
        data-name="Caminho 25120"
        d="M75.553 43.768a3.767 3.767 0 01-1.274-.223 3.392 3.392 0 01-1.136-.686l.738-.884a3.669 3.669 0 00.8.5 2.133 2.133 0 00.9.187 1.09 1.09 0 00.6-.142.448.448 0 00.215-.394v-.016a.485.485 0 00-.045-.215.453.453 0 00-.171-.175 1.822 1.822 0 00-.35-.162 5.968 5.968 0 00-.581-.171 6.986 6.986 0 01-.781-.235 2.214 2.214 0 01-.594-.321 1.294 1.294 0 01-.378-.475 1.646 1.646 0 01-.135-.707v-.016a1.653 1.653 0 01.145-.7 1.6 1.6 0 01.407-.54 1.817 1.817 0 01.626-.349 2.508 2.508 0 01.8-.122 3.391 3.391 0 011.148.187 3.336 3.336 0 01.962.536l-.649.941a4.122 4.122 0 00-.747-.41 1.909 1.909 0 00-.73-.15.886.886 0 00-.548.142.433.433 0 00-.182.353v.016a.5.5 0 00.053.239.474.474 0 00.191.183 1.84 1.84 0 00.379.154q.24.073.606.17a5.756 5.756 0 01.769.256 2.136 2.136 0 01.569.341 1.294 1.294 0 01.35.467 1.578 1.578 0 01.118.641v.016a1.758 1.758 0 01-.154.751 1.539 1.539 0 01-.427.552 1.919 1.919 0 01-.654.341 2.84 2.84 0 01-.84.12z"
        fill="#fff"
      />
    </svg>
  );
}

export default IconHexalab;
