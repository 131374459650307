import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: auto;
  position: relative;

  #mao-image {
    position: absolute;
    top: 800px;
    left: 0px;
  }

  @media screen and (max-width: 1500px) {
    #mao-image {
      left: -200px;
    }
  }

  @media screen and (max-width: 1000px) {
    #mao-image {
      top: 450px;
      left: -100px;
      width: 45%;
    }
  }

  @media screen and (max-width: 768px) {
    #mao-image {
      display: none;
    }
  }
`;
