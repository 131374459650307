import styled from 'styled-components';
import ReactInputMask from 'react-input-mask';

export const Content = styled.div`
  margin: 10px;
`;

export const Label = styled.label`
  color: #480d7e;
  font-size: 13px;
`;

export const Container = styled(ReactInputMask)`
  border: 1px solid #480d7e;
  border-radius: 5px;
  color: #480d7e;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
`;
