import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import { Container, Slide } from './style';

// importação de imagens
import slide1 from '../../assets/slide1.svg';
import slide2 from '../../assets/slide2.svg';
import slide3 from '../../assets/slide3.svg';
import slide4 from '../../assets/slide4.svg';
import slide5 from '../../assets/slide5.svg';
import slide6 from '../../assets/slide6.svg';
import slide7 from '../../assets/slide7.svg';
import slide8 from '../../assets/slide8.svg';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function SliderCards() {
  return (
    <Container>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <Slide>
            <img src={slide1} alt="" />
            <p>Serviços Bancários, Crédito Consignado…</p>
          </Slide>
        </SwiperSlide>

        <SwiperSlide>
          <Slide>
            <img src={slide2} alt="" />
            <p>Wallet, Capitalização</p>
          </Slide>
        </SwiperSlide>

        <SwiperSlide>
          <Slide>
            <img src={slide3} alt="" />
            <p>Pix, Ted, P2P…</p>
          </Slide>
        </SwiperSlide>

        <SwiperSlide>
          <Slide>
            <img src={slide4} alt="" />
            <p>Seguros e Previdência</p>
          </Slide>
        </SwiperSlide>

        <SwiperSlide>
          <Slide>
            <img src={slide6} alt="" />
            <p>Cashback e Cupom</p>
          </Slide>
        </SwiperSlide>

        <SwiperSlide>
          <Slide>
            <img src={slide7} alt="" />
            <p>Adquirência Gateway transparente</p>
          </Slide>
        </SwiperSlide>

        <SwiperSlide>
          <Slide>
            <img src={slide8} alt="" />
            <p>Totem de autoatendimento</p>
          </Slide>
        </SwiperSlide>

        <SwiperSlide>
          <Slide>
            <img src={slide5} alt="" />
            <p>E muito mais!</p>
          </Slide>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
}

export default SliderCards;
