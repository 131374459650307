import React from 'react';
import { Header, HeaderMobile, Connect } from './style';

import LogoWhatsapp from '../../components/Icons/LogoWhatsapp';
import arrowBottom from '../../assets/arrow-bottom.svg';

function HeaderSection() {
  return (
    <div id="init">
      <Header className="container">
        <div className="block-info">
          <h1>Seu banco digital com a sua cara está pronto!</h1>
          <p>
            Com o que há de mais avançado em tecnologia, você pode começar sua{' '}
            <span>Fintech</span>, sem grandes investimentos, sem burocracia e de
            forma super ágil. Segurança, possibilidade de customizações,
            diversos serviços financeiros e bancários integrados, marketplace,
            tudo isso em uma plataforma moderna, intuitiva com suas cores e
            marca.
          </p>
        </div>

        <div className="block-image" />
        <div className="logo-whatsapp">
          <LogoWhatsapp />
        </div>
      </Header>

      <HeaderMobile>
        <div className="content">
          <h1>Seu banco digital com a sua cara está pronto!</h1>
          <p>
            Com o que há de mais avançado em tecnologia, e segurança você pode
            começar sua <span>Fintech.</span>
          </p>
          <p>
            Sem grandes investimentos, sem burocracia e de forma super ágil.
          </p>
        </div>
      </HeaderMobile>

      <Connect>
        <div className="button">
          <a href="#whyHaveBank">
            <img src={arrowBottom} alt="" />
          </a>
        </div>
        <p>Conecte-se conosco!</p>
      </Connect>
    </div>
  );
}

export default HeaderSection;
