import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(-45deg, #710844 0%, #020b4e 100%);
  width: 100vw;
  display: flex;
  align-items: center;
  padding: 40px 0;

  div#content {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
  }

  /* Bloco com imagem e informações do Microbank */
  div.block-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    text-align: end;
  }

  div.text-info {
    margin-top: 20px;
    color: white;
    font-size: 18px;
  }

  /* Estilização das imagens da visa, pci e hexa */
  div.block-icons {
    display: flex;
  }
  div.block-icons .icon {
    margin: 10px;
  }

  /* Bloco com ícones das redes sociais*/
  div.block-social-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  @media screen and (max-width: 1000px) {
    div#content {
      display: grid;
      /* grid-template-rows: repeat(4, 1fr); */
      grid-template-rows: 1fr 150px 200px 100px;
      grid-template-columns: 1fr;
    }

    div.block-big-image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }

    div.block-icons {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /* Bloco com imagem e informações do Microbank */
    div.block-info {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }

    /* Bloco com ícones das redes sociais*/
    div.block-social-media {
      flex-direction: row;
      align-items: center;
      height: 100px;
    }

    .block-social-media a {
      margin: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px 0;

    div#content {
      grid-template-rows: 1fr 1fr 200px 100px;
    }

    div.icon {
      padding: 20px 0;
    }

    div.block-icons {
      display: flex;
      flex-direction: column;
    }
  }
`;
