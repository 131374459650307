import styled from 'styled-components';
import cube from '../../assets/cube.svg';
import cubeMobile from '../../assets/cube-mobile.svg';

export const Container = styled.section`
  background: linear-gradient(#ffffff 0%, #e7e7ed 100%);
  padding: 150px 0 250px 0;
  position: relative;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  h2 {
    color: #040242;
    font-size: 2em;
    margin-bottom: 10px;
  }

  h2.margin {
    margin-top: 40px;
  }

  h3 {
    color: #480d7e;
    font-size: 1.4em;
    line-height: 38px;
    margin-bottom: 30px;
  }

  p {
    color: #52526a;
    font-size: 1.2em;
  }

  p span {
    font-weight: 500;
  }

  .image {
    background: url(${cube});
    background-repeat: no-repeat;
    background-position: center right;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const Whitelabel = styled.section`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  bottom: -130px;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(72, 13, 126, 0.3);
  padding: 30px 20px;
  width: 90%;

  display: grid;
  grid-template-columns: 1fr 1fr;

  h2 {
    color: #020b4e;
    font-size: 2em;
    margin-bottom: 10px;
  }

  h3 {
    color: #480d7e;
    font-size: 1.4em;
  }

  .image-whitelabel img {
    position: absolute;
    bottom: 0px;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

// Componentes exibidos apenas quando o tamanho de tela for igual ou maior que 1000
export const ContainerTablet = styled.section`
  display: none;
  padding: 100px 0 0 0;

  h2 {
    color: #040242;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  h3 {
    color: #480d7e;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin: 40px 0;
  }

  .cube {
    background: url(${cubeMobile});
    background-repeat: no-repeat;
    background-position: center right;
  }

  p {
    color: #52526a;
    font-size: 12px;
    line-height: 22px;
    font-weight: 300;
  }

  p span {
    color: #52526a;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -5px;
  }

  .image img {
    width: 30%;
    margin: 0 auto;
    z-index: -1;
  }

  @media screen and (max-width: 1000px) {
    display: block;
  }

  @media screen and (max-width: 768px) {
    padding: 50px 10px;

    h2 {
      font-size: 18px;
    }

    h3 {
      font-size: 12px;
      line-height: 20px;
    }

    p {
      line-height: 20px;
    }
  }
`;
export const WhitelabelTablet = styled.section`
  display: none;
  background: #ffffff;
  box-shadow: 0px 3px 30px rgba(72, 13, 126, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 0;

  h2 {
    text-align: center;
  }

  p {
    color: #480d7e;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    width: 70%;
    text-align: center;
    margin: 20px auto;
  }

  .text-accordion p {
    color: #52526a;
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
  }

  .text-accordion p.purple {
    color: #480d7e;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  @media screen and (max-width: 1000px) {
    display: block;
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 18px;
    }

    p {
      line-height: 22px;
      width: 80%;
      margin: 20px auto;
    }
  }
`;
