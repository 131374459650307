import React, { useState } from 'react';
import { Container } from './style';

import IconFacebook from '../Icons/IconFacebook';
import IconLinkedin from '../Icons/IconLinkedin';
import Instagram from '../Icons/Instagram';

function MenuHamburguer() {
  const [selected, setSelected] = useState(false);

  return (
    <Container>
      <input
        id="navbar"
        type="checkbox"
        checked={selected}
        onChange={(e) => setSelected(e.target.value)}
      />
      <label htmlFor="navbar">
        <button
          type="button"
          className="menu"
          onClick={() => setSelected(!selected)}
        >
          <span className="hamburger" />
        </button>
      </label>

      <div className="social-media">
        <a href="/#">
          <IconFacebook />
        </a>

        <a href="/#">
          <IconLinkedin />
        </a>

        <a href="/#">
          <Instagram />
        </a>
      </div>

      <ul>
        <li>
          <a href="#init" onClick={() => setSelected(!selected)}>
            Início
          </a>
        </li>

        <li>
          <a href="#whyHaveBank" onClick={() => setSelected(!selected)}>
            Motivos
          </a>
        </li>

        <li>
          <a href="#completeBank" onClick={() => setSelected(!selected)}>
            Possibilidades
          </a>
        </li>

        <li>
          <a href="#bankServices" onClick={() => setSelected(!selected)}>
            Serviços
          </a>
        </li>

        <li>
          <a href="#build" onClick={() => setSelected(!selected)}>
            Customização
          </a>
        </li>

        <li>
          <a
            href="#join"
            onClick={() => setSelected(!selected)}
            className="contact"
          >
            Contato
          </a>
        </li>
      </ul>
    </Container>
  );
}

export default MenuHamburguer;
