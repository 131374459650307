import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 50px 0;

  img {
    width: 100%;
  }

  div#block-join {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  div#block-image-join {
    box-shadow: 0px 6px 10px rgba(72, 13, 126, 0.2);
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 150px;
    height: 150px;
  }

  div#block-join p {
    color: #020b4e;
    font-size: 1.2em;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  div#form {
    padding: 20px;
  }

  div#form h2 {
    color: #040242;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
  }

  div#form p {
    color: #040242;
    font-size: 1.2em;
    line-height: 30px;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;

    div#block-join p {
      color: #020b4e;
      font-size: 15px;
      line-height: 25px;
      margin-left: 20px;
    }

    div#form h2 {
      color: #040242;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    div#form p {
      color: #040242;
      font-size: 12px;
      line-height: 22px;
      line-height: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 40px 0;
    div#block-join {
      display: none;
    }

    div#form .teste {
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.9);
      margin-top: -80px;
    }

    div#form h2 {
      font-size: 18px;
      line-height: 26px;
    }

    div#form p {
      font-size: 12px;
      line-height: 20px;
    }
  }
`;

export const FormRegister = styled(Form)`
  margin-top: 30px;

  div.inline {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }

  div.inline-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
  }

  button#send-button {
    background-color: #ff9d00;
    color: #040242;
    padding: 10px 50px;
    font-size: 1.2em;
    margin: 5px;
    cursor: pointer;
  }

  button#send-button:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 1000px) {
    div.inline {
      grid-template-columns: 1fr;
    }

    div.inline-grid {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

    button#send-button {
      background-color: #ff9d00;
      color: #040242;
      padding: 10px 50px;
      font-size: 1.2em;
      margin: 5px;
      cursor: pointer;
    }

    button#send-button:hover {
      text-decoration: underline;
    }
  }
`;
