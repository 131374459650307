import React from 'react';
import { Accordion, AccordionItem } from 'react-light-accordion';
import '../../styles/accordion.css';

import {
  Container,
  Whitelabel,
  ContainerTablet,
  WhitelabelTablet,
} from './style';

// importação de imagens
import whitelabel from '../../assets/whitelabel-image.svg';

function GradientSection() {
  return (
    <>
      <Container>
        <div className="container">
          <div className="grid">
            <div>
              <h2>Moderno e seguro</h2>
              <h3>
                A Microbank utiliza o que existe de mais moderno e seguro em
                tecnologia para Digital Banking.
              </h3>
              <p>
                Utilizamos um poderoso Core Banking com tecnologias de ponta,
                multiplataforma, altamente parametrizável, flexível e robusto,
                escalável e fácil de integrar. Com soluções que se complementam,
                podemos formar diferentes combinações de acordo com cada
                necessidade. O Microbank cria soluções inovadoras para o mercado
                financeiro sempre seguindo todas as regras e exigências do Banco
                Central. Oferecemos a solidez dos bancos convencionais e a
                modernidade das Fintechs.
              </p>

              <h2 className="margin">Totalmente modular</h2>
              <p>
                Nosso modelo <span>plataforma modular</span> permite que você se
                desenvolva sem amarras. Precisa de maior poder de processamento
                de transações? Nós oferecemos. Quer oferecer novos serviços?
                Estamos prontos para implementar.
              </p>
            </div>

            <div className="image" />
          </div>
        </div>

        <Whitelabel>
          <div>
            <h2>E whitelabel</h2>
            <h3>
              A solução Microbank foi desenvolvida para cuidar do backoffice
              enquanto você e sua marca crescem.
            </h3>
          </div>

          <div className="image-whitelabel">
            <img src={whitelabel} alt="" />
          </div>
        </Whitelabel>
      </Container>

      <ContainerTablet>
        <div className="container">
          <h2>Moderno e seguro</h2>
          <h3>
            A Microbank utiliza o que existe de mais moderno e seguro em
            tecnologia para Digital Banking.
          </h3>

          <div className="grid">
            <div>
              <h2>Totalmente modular</h2>
              <p>
                Nossa <span>plataforma modular</span> permite que você se
                desenvolva sem amarras. Precisa de maior poder de processamento
                de transações? Nós oferecemos. Quer oferecer novos serviços?
                Estamos prontos para implementar.
              </p>
            </div>

            <div className="cube" />
          </div>

          <div className="image">
            <img src={whitelabel} alt="" />
          </div>

          <WhitelabelTablet>
            <h2>E Whitelabel</h2>
            <p>
              A solução Microbank foi desenvolvida para cuidar do backoffice,
              enquanto você e sua marca crescem.
            </p>

            <Accordion atomic>
              <AccordionItem title="Saiba Mais">
                <div className="text-accordion">
                  <p>
                    Utilizamos um poderoso Core Banking com tecnologias de
                    ponta, multiplataforma, altamente parametrizável, flexível e
                    robusto, escalável e fácil de integrar.
                  </p>

                  <p>
                    Com soluções que se complementam, podemos formar diferentes
                    combinações de acordo com cada necessidade.
                  </p>
                </div>

                <div className="text-accordion">
                  <p>
                    O Microbank cria soluções inovadoras para o mercado
                    financeiro sempre seguindo todas as regras e exigências do
                    Banco Central.
                  </p>
                </div>

                <div className="text-accordion">
                  <p className="purple">
                    Oferecemos a solidez dos bancos convencionais e a
                    modernidade das Fintechs
                  </p>
                </div>
              </AccordionItem>
            </Accordion>
          </WhitelabelTablet>
        </div>
      </ContainerTablet>
    </>
  );
}

export default GradientSection;
