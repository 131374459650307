import React from 'react';
import { Accordion, AccordionItem } from 'react-light-accordion';

import { Container, ContainerTablet } from './style';
import '../../styles/accordion.css';

function BlockTextSection() {
  return (
    <>
      <Container className="container">
        <div>
          <p>
            Ser lembrado pelo cliente como uma marca que oferece soluções é um
            dos propósitos de todo empreendedor. Ter sua marca em um segmento
            que cresce em ritmo exponencial, é sem duvida uma grande vantagem
            competitiva e estratégia escolhida pelas empresas que mais crescem
            no mercado.
            <span>
              Resolver as dores, as dificuldades de seus clientes, gera
              fidelidade e lucro.
            </span>
          </p>

          <p>
            Imagine toda essa solução gerando lucro e monetizando sua atividade.
            Todos os negócios gerados dentro de nosso marketplace oferecem
            oportunidade de receita para sua empresa.
          </p>
        </div>

        <div>
          <p>
            Agora, é hora de passar toda essa expertise para modelos de negócios
            disruptivos. O nosso intuito é oferecer às organizações a
            oportunidade de expandir seus negócios através de suas próprias
            Fintechs.
          </p>

          <p>
            <span>
              As Fintechs têm grande potencial de transformar completamente as
              trocas econômicas que conhecemos atualmente
            </span>
            , elas vão substituir os grandes bancos. Vamos fazer parte desta
            transformação juntos!
          </p>

          <p className="purple">Saia na frente!</p>
        </div>
      </Container>

      <ContainerTablet>
        <Accordion atomic>
          <AccordionItem title="Saiba Mais">
            <div>
              <p>
                Ser lembrado pelo cliente como uma marca que oferece soluções é
                um dos propósitos de todo empreendedor. Ter sua marca em um
                segmento que cresce em ritmo exponencial, é sem duvida uma
                grande vantagem competitiva e estratégia escolhida pelas
                empresas que mais crescem no mercado.
                <span>
                  Resolver as dores, as dificuldades de seus clientes, gera
                  fidelidade e lucro.
                </span>
              </p>

              <p>
                Imagine toda essa solução gerando lucro e monetizando sua
                atividade. Todos os negócios gerados dentro de nosso marketplace
                oferecem oportunidade de receita para sua empresa.
              </p>
            </div>

            <div>
              <p>
                Agora, é hora de passar toda essa expertise para modelos de
                negócios disruptivos. O nosso intuito é oferecer às organizações
                a oportunidade de expandir seus negócios através de suas
                próprias Fintechs.
              </p>

              <p>
                <span>
                  As Fintechs têm grande potencial de transformar completamente
                  as trocas econômicas que conhecemos atualmente
                </span>
                , elas vão substituir os grandes bancos. Vamos fazer parte desta
                transformação juntos!
              </p>

              <p className="purple">Saia na frente!</p>
            </div>
          </AccordionItem>
        </Accordion>
      </ContainerTablet>
    </>
  );
}

export default BlockTextSection;
