import React from 'react';

import { Container, ContainerMobile } from './style';
import built from '../../assets/built.svg';
import builtTablet from '../../assets/built-tablet.svg';
import buildMobile from '../../assets/build-mobile.png';

import SlideBuild from '../../components/SlideBuild';

function BuiltSection() {
  return (
    <div id="build">
      <Container>
        <div className="container">
          <div className="grid">
            <div className="texts">
              <h2>Build to suit</h2>

              <h3>
                A ideia de construir algo pensando em resolver a necessidade de
                quem vai usar é o conceito built to suit, muito utilizado em
                construções.
              </h3>

              <p>
                Mas podemos pensar assim também quando o assunto é banco
                digital. Você escolhe as soluções e nós entregamos o seu banco
                do jeito que você idealizou.{' '}
                <span>
                  Conecte os serviços que achar melhor para a sua ideia.
                </span>
              </p>
              <p>
                Seu cliente precisa pagar boletos? O seu banco pode ter. Precisa
                fazer operações de câmbio? Por que não? Temos vários serviços
                que podem ser
                <span>“plugados”</span> ao seu banco oferecendo uma solução
                direta e completa para as necessidades dos seus clientes.
              </p>
            </div>

            <div className="image">
              <img src={built} alt="" />
            </div>

            <div className="image-tablet">
              <img src={builtTablet} alt="" />
            </div>
          </div>
        </div>
      </Container>

      <ContainerMobile>
        <div className="texts">
          <h2>Build to suit</h2>

          <h3>
            A ideia de construir algo pensando em resolver a necessidade de quem
            vai usar é o conceito built to suit, muito utilizado em construções.
          </h3>

          <p>
            Mas podemos pensar assim também quando o assunto é banco digital.
            Você escolhe as soluções e nós entregamos o seu banco do jeito que
            você idealizou.{' '}
            <span>Conecte os serviços que achar melhor para a sua ideia.</span>
          </p>
          <p>
            Seu cliente precisa pagar boletos? O seu banco pode ter. Precisa
            fazer operações de câmbio? Por que não? Temos vários serviços que
            podem ser <span>“plugados”</span> ao seu banco oferecendo uma
            solução direta e completa para as necessidades dos seus clientes.
          </p>
        </div>

        <div className="block-slide">
          <div className="block-image">
            <img src={buildMobile} alt="" />
          </div>

          <div className="background" />

          <SlideBuild />
        </div>
      </ContainerMobile>
    </div>
  );
}

export default BuiltSection;
