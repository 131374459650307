import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(45deg, #ececf2 1%, #ffffff 100%);

  .grid {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 200px;
  }

  .image-future-of-microbanks {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-future-of-microbanks img {
    width: 20%;
  }

  div.info-future-of-microbanks {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.info-future-of-microbanks p {
    color: #020b4e;
    font-size: 1.2em;
    text-align: center;
  }

  @media screen and (max-width: 1000px) {
    .grid {
      height: auto;
      grid-template-columns: 1fr 3fr;
    }

    .image-future-of-microbanks img {
      width: 100%;
    }

    div.info-future-of-microbanks {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div.info-future-of-microbanks p {
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    .grid {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      height: auto;
    }

    div.image-future-of-microbanks {
      display: none;
    }

    div.info-future-of-microbanks {
      padding: 20px 0;
    }
  }
`;
