import React from 'react';
import { Accordion, AccordionItem } from 'react-light-accordion';

import { ContainerDesktop, ContainerTablet, ContainerMobile } from './style';
import '../../styles/accordion.css';

import phoneImage from '../../assets/phone-complete.svg';
import notebookImage from '../../assets/notebook-complete.svg';
import totemIllustration from '../../assets/totem-illustration.svg';
import maoImage from '../../assets/mao.svg';

function CompleteBench() {
  return (
    <div id="completeBank">
      <ContainerDesktop className="container">
        <div className="image-complete-bench" />

        <div className="info-complete-bench">
          <div>
            <h2>Um banco completo</h2>

            <h3>
              A Microbank tem o banco do tamanho que você precisa. Digital
              banking? Temos! Dashboard e internet banking? Sim! Totem de
              autoatentimento para as suas lojas, fábricas ou escritórios? Sem
              problemas, bastar escolher!{' '}
            </h3>

            <p>
              Além dos canais físicos, a Microbank permite aos seus clientes o
              acesso a uma série de serviços financeiros a partir do seu
              negócio. Você pode moldar a sua oferta de acordo com os objetivos
              estratégicos da empresa. Abrir contas digitais, emissão de
              boletos, TED, PIX, crédito, cartão pré-pago, saque, câmbio,
              seguro, adquirência, pagamento de contas, gateway de pagamento,
              enfim, são muitas opções.
            </p>
            <p>
              Sem contar outros serviços que com certeza seus clientes irão
              valorizar como recarga de celular, bilhete único, cashback,
              gerador de cupom, delivery e muitas outras possibilidades. Você
              encontra todas essas opções em nosso marketplace, e mais, pode
              escolher aquelas que melhor se encaixam em sua proposta.
            </p>

            <div className="button">
              <a href="/#">Saiba mais!</a>
            </div>
          </div>

          <div className="images">
            <div id="phone">
              <img src={phoneImage} alt="" />
            </div>

            <div id="notebook">
              <img src={notebookImage} alt="" />
            </div>
          </div>
        </div>
      </ContainerDesktop>

      <ContainerTablet id="completeBank" className="container">
        <div id="notebook">
          <img src={notebookImage} alt="" />
        </div>

        <div className="images">
          <div id="phone">
            <img src={phoneImage} alt="" />
          </div>

          <div id="totem">
            <img src={totemIllustration} alt="" />
          </div>
        </div>

        <div className="info-complete-bench">
          <div>
            <h2>Um banco completo</h2>

            <h3>
              A Microbank tem o banco do tamanho que você precisa. Digital
              banking? Temos! Dashboard e internet banking? Sim! Totem de
              autoatentimento para as suas lojas, fábricas ou escritórios? Sem
              problemas, bastar escolher!{' '}
            </h3>

            <p>
              Além dos canais físicos, a Microbank permite aos seus clientes o
              acesso a uma série de serviços financeiros a partir do seu
              negócio. Você pode moldar a sua oferta de acordo com os objetivos
              estratégicos da empresa. Abrir contas digitais, emissão de
              boletos, TED, PIX, crédito, cartão pré-pago, saque, câmbio,
              seguro, adquirência, pagamento de contas, gateway de pagamento,
              enfim, são muitas opções.
            </p>
            <p>
              Sem contar outros serviços que com certeza seus clientes irão
              valorizar como recarga de celular, bilhete único, cashback,
              gerador de cupom, delivery e muitas outras possibilidades. Você
              encontra todas essas opções em nosso marketplace, e mais, pode
              escolher aquelas que melhor se encaixam em sua proposta.
            </p>

            <div className="button">
              <a href="/#">Saiba mais!</a>
            </div>
          </div>
        </div>
      </ContainerTablet>

      <ContainerMobile>
        <div className="first">
          <img src={totemIllustration} alt="" />

          <div>
            <h2>Um banco completo</h2>
            <p>
              A Microbank tem o banco do jeito que você quer. Precisa de digital
              banking, dashboard, internet banking e totem de autoatentimento?
            </p>
            <h3>Sem problemas, basta escolher!</h3>
          </div>
        </div>

        <div className="second">
          <img src={maoImage} alt="" id="mao" />
          <img src={notebookImage} alt="" id="notebook" />
        </div>

        <Accordion atomic>
          <AccordionItem title="Saiba Mais">
            <div>
              <p className="accordion">
                Além dos canais físicos, a Microbank permite aos seus clientes o
                acesso a uma série de serviços financeiros a partir do seu
                negócio. Você pode moldar a sua oferta de acordo com os
                objetivos estratégicos da empresa. Abrir contas digitais,
                emissão de boletos, TED, PIX, crédito, cartão pré-pago, saque,
                câmbio, seguro, adquirência, pagamento de contas, gateway de
                pagamento, enfim, são muitas opções.
              </p>

              <p className="accordion">
                Sem contar outros serviços que com certeza seus clientes irão
                valorizar como recarga de celular, bilhete único, cashback,
                gerador de cupom, delivery e muitas outras possibilidades.
              </p>
            </div>

            <div>
              <p className="purple">
                Você encontra todas essas opções em nosso marketplace, e mais,
                pode escolher aquelas que melhor se encaixam em sua proposta.
              </p>
            </div>
          </AccordionItem>
        </Accordion>
      </ContainerMobile>
    </div>
  );
}

export default CompleteBench;
