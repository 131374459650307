import React from 'react';

// Importação de Estilos
import { Container } from './style';

// Importação de componentes
import Nav from '../../components/Nav';
import MenuHamburguer from '../../components/MenuHamburguer';
import Footer from '../../components/Footer';
import maoImage from '../../assets/mao.svg';

// Importação das sessões do site
import Header from '../../Layout/HeaderSection';
import WhyHaveBank from '../../Layout/WhyHaveBankSection';
import FutureOfMicrobanks from '../../Layout/FutureOfMicrobanksSection';
import BlockText from '../../Layout/BlockTextSection';
import CompleteBench from '../../Layout/CompleteBenchSection';
import Gradient from '../../Layout/GradientSection';
import BankingService from '../../Layout/BankingServiceSection';
import Built from '../../Layout/BuiltSection';
import GoToMarket from '../../Layout/GoToMarketSection';
import Join from '../../Layout/JoinSection';

function Home() {
  return (
    <Container>
      <div className="container">
        <Nav />
        <MenuHamburguer />
      </div>

      <Header />

      <div className="container">
        <WhyHaveBank />
      </div>

      <FutureOfMicrobanks />
      <img src={maoImage} alt="" id="mao-image" />

      <BlockText />
      <CompleteBench />
      <Gradient />

      <div className="container">
        <BankingService />
      </div>

      <Built />
      <GoToMarket />
      <Join />

      <Footer />
    </Container>
  );
}

export default Home;
