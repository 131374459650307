import * as React from 'react';

function IconFacebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.998"
      height="49.996"
      viewBox="0 0 49.998 49.996"
    >
      <defs>
        <filter
          id="Caminho_25348"
          x="0"
          y="0"
          width="49.998"
          height="49.996"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood floodColor="#e0beff" floodOpacity="0.502" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Caminho_25348)">
        <path
          id="Caminho_25348-2"
          data-name="Caminho 25348"
          d="M22345.332,22166v-15.639h5.842l.264-5.627h-6.105v-4.113c0-1.549.32-2.486,2.352-2.486,1.875,0,3.592.018,3.592.018l.135-5.258a34.1,34.1,0,0,0-3.963-.219c-5.627,0-8.074,3.467-8.074,7.25v4.809h-4.135v5.627h4.135V22166h-13.758a7.292,7.292,0,0,1-7.307-7.3V22133.3a7.287,7.287,0,0,1,7.307-7.3h25.391a7.283,7.283,0,0,1,7.3,7.3v25.4a7.283,7.283,0,0,1-7.3,7.3Z"
          transform="translate(-22313.31 -22121)"
          fill="none"
          stroke="#92a1fd"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default IconFacebook;
