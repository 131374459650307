import React from 'react';
import { Container, ContainerMobile } from './style';

import iconBankServices from '../../assets/icon-bank-services.svg';
import iconCashback from '../../assets/icon-cashback.svg';
import iconCard from '../../assets/icon-card.svg';
import iconPayments from '../../assets/icon-payments.svg';
import iconCoupons from '../../assets/icon-coupons.svg';
import iconServices from '../../assets/icon-services.svg';
import surpriseMobile from '../../assets/surprise-mobile.png';

function BankingServiceSection() {
  return (
    <div div id="bankServices">
      <Container>
        <div className="info">
          <h2>Banking as a service!</h2>
          <h3>“Serviços bancários são essenciais, bancos não”Bill Gates</h3>

          <p>
            Serviços especializados serão o futuro do setor financeiro. A ideia
            de banking as a service nasce dessa visão. Você pode ser o banco que
            quiser oferecendo os serviços que melhor se encaixam no seu modelo
            de negócios. Aqui o seu banco pode ter:
          </p>

          <div className="grid">
            <div className="item">
              <div className="image">
                <img src={iconBankServices} alt="" />
              </div>
              <div>
                <h4>Serviços Bancários</h4>
                <p>
                  Abertura de contas, saques, depósitos, transferências via TED
                  e Pix, P2P…
                </p>
              </div>
            </div>

            <div className="item">
              <div className="image">
                <img src={iconCashback} alt="" />
              </div>
              <div>
                <h4>Wallet e cashback</h4>
                <p>
                  Ofereça diversas vantagens para seu cliente, como Cashback uma
                  Wallet
                </p>
              </div>
            </div>

            <div className="item">
              <div className="image">
                <img src={iconCard} alt="" />
              </div>
              <div>
                <h4>Cartão pré-pago</h4>
                <p>
                  Imagine um cartão Visa com a sua marca! E melhor, com um
                  visual único e personalizado.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="image">
                <img src={iconPayments} alt="" />
              </div>
              <div>
                <h4>Pagamentos</h4>
                <p>
                  Pagamentos de conta e boletos, emissão de boletos,
                  parcelamento…
                </p>
              </div>
            </div>

            <div className="item">
              <div className="image">
                <img src={iconCoupons} alt="" />
              </div>
              <div>
                <h4>Cupons e Recargas</h4>
                <p>
                  Integração com marketplace, recarga de celular, bilhete único,
                  compra de jogos…
                </p>
              </div>
            </div>

            <div className="item">
              <div className="image">
                <img src={iconServices} alt="" />
              </div>
              <div>
                <h4>Outros serviços</h4>
                <p>
                  Previdência, seguros, operações de câmbio, capitalização,
                  onboarding digital e muito mais!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="image-background">
          <a href="/#">Fale conosco!</a>
        </div>
      </Container>

      <ContainerMobile>
        <h2>Banking as a service!</h2>
        <h3>“Serviços bancários são essenciais, bancos não”Bill Gates</h3>

        <p>
          Serviços especializados serão o futuro do setor financeiro. A ideia de
          banking as a service nasce dessa visão. Você pode ser o banco que
          quiser oferecendo os serviços que melhor se encaixam no seu modelo de
          negócios. Aqui o seu banco pode ter:
        </p>

        <div className="img">
          <img src={surpriseMobile} alt="" />
        </div>

        <div className="background">
          <div className="block-items">
            <div className="item">
              <div className="image">
                <img src={iconBankServices} alt="" />
              </div>
              <div>
                <h4>Serviços Bancários</h4>
                <p>
                  Abertura de contas, saques, depósitos, transferências via TED
                  e Pix, P2P…
                </p>
              </div>
            </div>

            <div className="item">
              <div className="image">
                <img src={iconCashback} alt="" />
              </div>
              <div>
                <h4>Wallet e cashback</h4>
                <p>
                  Ofereça diversas vantagens para seu cliente, como Cashback uma
                  Wallet
                </p>
              </div>
            </div>

            <div className="item">
              <div className="image">
                <img src={iconCard} alt="" />
              </div>
              <div>
                <h4>Cartão pré-pago</h4>
                <p>
                  Imagine um cartão Visa com a sua marca! E melhor, com um
                  visual único e personalizado.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="image">
                <img src={iconPayments} alt="" />
              </div>
              <div>
                <h4>Pagamentos</h4>
                <p>
                  Pagamentos de conta e boletos, emissão de boletos,
                  parcelamento…
                </p>
              </div>
            </div>

            <div className="item">
              <div className="image">
                <img src={iconCoupons} alt="" />
              </div>
              <div>
                <h4>Cupons e Recargas</h4>
                <p>
                  Integração com marketplace, recarga de celular, bilhete único,
                  compra de jogos…
                </p>
              </div>
            </div>

            <div className="item">
              <div className="image">
                <img src={iconServices} alt="" />
              </div>
              <div>
                <h4>Outros serviços</h4>
                <p>
                  Previdência, seguros, operações de câmbio, capitalização,
                  onboarding digital e muito mais!
                </p>
              </div>
            </div>
          </div>
        </div>
      </ContainerMobile>
    </div>
  );
}

export default BankingServiceSection;
