import React from 'react';

import { Container } from './style';

import illustrationStrengthens from '../../assets/illustration-strengthens.svg';
import illustrationClients from '../../assets/illustration-clients.svg';
import illustrationMonetize from '../../assets/illustration-monetize.svg';
import illustrationTiming from '../../assets/illustration-timing.svg';
import phoneImage from '../../assets/phone-complete.svg';

function WhyHaveBank() {
  return (
    <Container id="whyHaveBank">
      <div />

      <div className="info">
        <h3>Por que ter um banco com a sua marca?</h3>
        <img src={phoneImage} alt="" id="img-mobile" />

        <div className="block-items">
          <div className="item">
            <div className="img">
              <img src={illustrationStrengthens} alt="" />
            </div>
            <p>Fortalece o posicionamento da sua marca</p>
          </div>

          <div className="item">
            <div className="img">
              <img src={illustrationClients} alt="" />
            </div>
            <p>Aumenta sua base de clientes</p>
          </div>

          <div className="item">
            <div className="img">
              <img src={illustrationMonetize} alt="" />
            </div>
            <p>Monetiza sua atividade com serviços e produtos exclusivos</p>
          </div>

          <div className="item">
            <div className="img">
              <img src={illustrationTiming} alt="" />
            </div>
            <p>Mais simples e rápido do que você imagina</p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default WhyHaveBank;
