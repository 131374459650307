import * as React from 'react';

function LogoWhatsapp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={69.074}
      height={69.543}
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.421}
          y1={1.077}
          x2={0.721}
          y2={0.123}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#60b552" />
          <stop offset={1} stopColor="#57ef3f" />
        </linearGradient>
        <filter
          id="prefix__b"
          x={0}
          y={0}
          width={69.074}
          height={69.544}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={1.5} result="blur" />
          <feFlood floodColor="#480d7e" floodOpacity={0.302} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Grupo 11921">
        <path
          data-name="whatsapp"
          d="M28 52.482a23.879 23.879 0 009.512-1.953 24.157 24.157 0 0013.062-13.066 24.139 24.139 0 000-19.024A24.158 24.158 0 0037.508 5.373a24.139 24.139 0 00-19.024 0A24.158 24.158 0 005.418 18.439a24.29 24.29 0 002.734 23.887l-3.085 9.1 9.453-3.007A24.018 24.018 0 0028 52.482z"
          transform="translate(7.088 3.038)"
          fill="url(#prefix__a)"
        />
        <g transform="translate(-.003 -.002)" filter="url(#prefix__b)">
          <path
            data-name="whatsapp"
            d="M43.015 34.816a17.833 17.833 0 013.809 1.719 16.923 16.923 0 013.5 2.069 1.657 1.657 0 01.078.585 8.228 8.228 0 01-.665 2.969 5.243 5.243 0 01-2.773 2.559 9.238 9.238 0 01-3.987 1.033q-2.226 0-7.422-2.422a22.271 22.271 0 01-6.641-4.609 51.515 51.515 0 01-5.781-7.227q-2.812-4.179-2.773-7.578v-.312a8.639 8.639 0 012.891-6.172 2.955 2.955 0 012.031-.859 6.172 6.172 0 01.7.059 6.28 6.28 0 00.741.059 1.606 1.606 0 011.035.253 2.817 2.817 0 01.606 1.075q.312.781 1.288 3.437a23.212 23.212 0 01.977 2.931 3.969 3.969 0 01-1.347 2.246q-1.342 1.43-1.342 1.819a1.114 1.114 0 00.194.585 17.611 17.611 0 003.984 5.352 24.929 24.929 0 005.9 3.944 1.73 1.73 0 00.859.274q.585 0 2.109-1.894t2.031-1.894zm-7.929 20.7a23.879 23.879 0 009.512-1.953A24.157 24.157 0 0057.664 40.5a24.139 24.139 0 000-19.024A24.158 24.158 0 0044.598 8.41a24.139 24.139 0 00-19.024 0 24.158 24.158 0 00-13.065 13.066 24.29 24.29 0 002.731 23.886l-3.085 9.1 9.453-3.007a24.018 24.018 0 0013.475 4.062zm0-53.984a28.62 28.62 0 0111.426 2.347 29.114 29.114 0 0115.683 15.683 29.02 29.02 0 010 22.85 29.114 29.114 0 01-15.683 15.685 28.6 28.6 0 01-11.426 2.343 29 29 0 01-14.258-3.672L4.54 62.003l5.312-15.819a29.283 29.283 0 01-1.875-26.622A29.114 29.114 0 0123.661 3.879a28.572 28.572 0 0111.425-2.344z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default LogoWhatsapp;
