import * as React from 'react';

function LogoMicrobank(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={107.089}
      height={109.083}
      {...props}
    >
      <g data-name="Grupo 11627">
        <path
          data-name="Caminho 24395"
          d="M79.832 11.53a1.215 1.215 0 00-1.647-1.193 2.868 2.868 0 00-2.909-1.183c-1.954.289-3.323 1.924-4.761 3.145a22.617 22.617 0 00-3.673 3.5c-.648.866.256 1.886 1.124 1.959a12.253 12.253 0 005.4-1.156c.427-.172.865-.348 1.3-.534-1.78 2.615-3.78 5.13-4.618 8.144a2.134 2.134 0 00.311 1.8 3.773 3.773 0 00-.739.755c-1.515 2.081-.872 4.635-2.162 6.881a55.863 55.863 0 01-5.5 7.158c-1.354 1.658-3.363 4.8-5.577 5.351-2.532.626-4.524-1.263-7.052.609a3.719 3.719 0 00-.912.963c-1.013-2.661-3.443-3.79-5.573-5.484-1.742-1.385-3.574-3.551-5.762-4.17a1.71 1.71 0 00-2.132 1.624c-.258 2.468 1.719 5.129 3.129 6.991 1.8 2.379 3.988 5.083 7.17 5.224a3.012 3.012 0 001.879-.611 3.823 3.823 0 01-.622.988c-2.007 2.28-5.964.886-8.157-.173-3.8-1.835-8-6.066-12.51-5.363a1.35 1.35 0 00-.994 1.305l.069.811a.241.241 0 00.168.221c3.447 1.244 6.217 5.121 9.042 7.378 2.56 2.046 5.462 3.819 8.775 4.212a11.338 11.338 0 007.9-2.02 9.877 9.877 0 002.776-2.912 7.437 7.437 0 00.733-2.063 7.814 7.814 0 002.034.3 10.341 10.341 0 004.045-1.2c2.89-1.522 5.005-4.026 7.039-6.508 4.209-5.14 9.939-10.7 8.512-17.915a3.219 3.219 0 00-1.183-1.838 8.645 8.645 0 004.434-5.3c1.117-3.36.504-6.292.643-9.696z"
          fill="#e7e7ed"
        />
        <path
          data-name="Caminho 24396"
          d="M104.067 77.306c-1.722-.722-3.443-2.712-4.88-3.879-1.829-1.486-3.623-3-5.41-4.531-2.093-1.8-4.205-3.853-6.483-5.585 3.4-4.033 6.626-9.458 5.329-14.634a2.3 2.3 0 00-4.151-.542c-1.626 2.406-2.7 5.021-4.59 7.269a20.227 20.227 0 003.828-9.875 1.536 1.536 0 00.781-1.368c-.086-2.07-.963-3.2-1.962-4.7a10.648 10.648 0 00-.9-1.471c-1.813-3.305-6.685-.391-5.024 2.936 2.974 5.96-6.576 13.694-10.606 17.01a78.09 78.09 0 01-17.7 10.525c-6.3 2.833-12.935 4.772-19.279 7.457-2.828 1.2-5.418 3.018-6.667 5.512-.179-.159-.352-.323-.542-.474a.876.876 0 00-1.45.844 27.049 27.049 0 001.428 4.848 2.086 2.086 0 00.693.947 2.851 2.851 0 00-3.8 2.638c-.039 1.515.738 2.463 1.272 3.792a4.47 4.47 0 01.242 1.023c.007.047.028.043.05.022a6.48 6.48 0 00-.107.2 20.086 20.086 0 00-2.094 4.094 2.315 2.315 0 00.595 2.249 3.085 3.085 0 00.437.342 2.337 2.337 0 002.363.02 8.609 8.609 0 001.224-.806 7.637 7.637 0 00-2.159 5.1.955.955 0 001.612.668c3.314-3.073 8.22-5.506 10.423-9.578a9.251 9.251 0 00.977-6.544c5.068-2.525 10.1-5.012 15.381-7.145 5.945-2.4 11.892-4.793 17.6-7.73a60.108 60.108 0 0013.02-8.721 43.787 43.787 0 006.312 8.634c-2.67 1.392-4.65 4.093-7.005 5.954-2.959 2.34-6.067 4.495-8.965 6.913-4.101 3.415-10.056 9.34-5.413 14.615a.925.925 0 001.535-.894c-2.212-6.26 9.626-11.4 13.656-13.991 3.216-2.064 8.04-5.05 9.409-9 3.223 3.584 7.332 7.624 12 5.389 3.594-1.727 1.914-6.32-.98-7.533zm-73.511 9.729a3.347 3.347 0 00.674 5.231c2.387 1.13-.185 4.26-2.135 6.3a10.207 10.207 0 00.645-1.047 7.928 7.928 0 00-2.425-9.491 1.836 1.836 0 002.532-1.387c.446-2.572 4.338-3.41 6.927-4.062a52.163 52.163 0 00-6.218 4.456z"
          fill="#e7e7ed"
        />
        <path
          data-name="Caminho 24397"
          d="M23.961 43.508a2.721 2.721 0 00-3.273-.423 5.969 5.969 0 00-1.417 1.478c-.055.08-.254.339-.216.294-.158.2-.319.39-.474.591-.326.423-.3.4-.584.745a1.965 1.965 0 00-.386.181c-.09.044-.162.074-.235.1a1.661 1.661 0 00-.719 1.018c-.37 1.351 1 2.459 2.225 2.522a5.042 5.042 0 002.286-.544c.642.748 1.95.235 2.273-.625a18.581 18.581 0 01.942-2.067 2.764 2.764 0 00-.422-3.27z"
          fill="#e7e7ed"
        />
        <path
          data-name="Caminho 24398"
          d="M85.772 73.645c-3.574 1.611-6.611 4.605-9.6 7.1-3.292 2.751-6.749 5.51-9.425 8.879a.846.846 0 001.021 1.322c3.749-1.9 7.047-4.66 10.332-7.257 3.235-2.557 6.884-5.037 9.312-8.405.781-1.08-.677-2.074-1.64-1.639z"
          fill="#e7e7ed"
        />
        <path
          data-name="Caminho 24399"
          d="M64.131 26.212c-2.607-2.581-6.067-4.375-8.886-6.763-2.6-2.205-4.837-4.869-7.653-6.809-.588-.405-1.182.262-1.087.836.632 3.789 3.056 7.079 5.446 9.979 2.48 3.014 5.654 6.213 9.325 7.656 2.829 1.117 4.808-2.967 2.855-4.899z"
          fill="#e7e7ed"
        />
        <path
          data-name="Caminho 24400"
          d="M56.087 34.585c-2.569-1.2-5.42-1.342-7.958-2.734-1.107-.607-5.862-3.015-5.044-4.816a.283.283 0 00-.384-.384c-3.028 1.692-1.191 4.818.61 6.914 2.521 2.934 7.447 6.268 11.474 5.828a2.688 2.688 0 002.487-1.89 2.4 2.4 0 00.092-.686 2.557 2.557 0 00-1.277-2.232z"
          fill="#e7e7ed"
        />
        <g data-name="Grupo 11626">
          <g data-name="Grupo 11611">
            <path
              data-name="Caminho 24401"
              d="M14.743 57.682a1.226 1.226 0 01-1.112-.71c-2.707-5.845-4.023-10.627-4.023-14.62 0-7.15 3.94-10.29 7.673-12.145-.041-.47-.062-.928-.062-1.381 0-7.561 5.667-10.011 8.507-10.928.451-6.3 6.694-9.23 9.336-9.556 3.83-5.7 11.11-7.123 16.993-8.272a3.694 3.694 0 01.717-.07c3.155 0 4.965 3.981 5.444 5.2a51.993 51.993 0 0011.59 17.916 6.532 6.532 0 012.018 4.6c0 3.786-2.658 4.2-4.417 4.479-.272.043-.548.086-.82.14a1.225 1.225 0 01-.481-2.4c.305-.061.614-.11.921-.158 1.86-.293 2.347-.436 2.347-2.06a4.11 4.11 0 00-1.3-2.868A54.433 54.433 0 0155.932 6.1c-.713-1.819-1.968-3.65-3.16-3.65a1.286 1.286 0 00-.243.024c-6.876 1.343-12.806 2.851-15.726 7.7a1.246 1.246 0 01-1.112.592h-.17c-1.25 0-7.367 2.142-7.367 7.865v.162a1.224 1.224 0 01-.878 1.2c-5.258 1.549-7.6 4.276-7.6 8.839a13.941 13.941 0 00.142 1.941 1.225 1.225 0 01-.7 1.284c-3.661 1.674-7.049 4.081-7.049 10.3 0 3.629 1.242 8.074 3.8 13.592a1.225 1.225 0 01-1.112 1.739z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11612">
            <path
              data-name="Caminho 24402"
              d="M61.248 42.155a11.479 11.479 0 01-5.25-1.2 1.225 1.225 0 011.095-2.192 8.965 8.965 0 004.155.943c3.441 0 4.09-.554 4.09-3.49 0-1.125-.229-3.841-2.354-4.739-9.5-4.022-13.21-10.122-15.92-14.571-2.087-3.43-3.282-5.219-5.561-5.219-1.485 0-3.016 1.52-3.343 3.32a1.226 1.226 0 01-2.412-.439c.543-2.99 3.07-5.331 5.753-5.331 3.761 0 5.565 2.963 7.654 6.4 2.677 4.4 6.01 9.874 14.786 13.586 2.446 1.035 3.85 3.585 3.85 7 .001 4.932-2.501 5.932-6.543 5.932z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11613">
            <path
              data-name="Caminho 24403"
              d="M52.359 47.962c-6.084 0-10.407-3.876-14.22-7.3-2.972-2.665-5.538-4.966-8.281-4.966a14.27 14.27 0 00-4.077.719 1.225 1.225 0 11-.7-2.346 16.612 16.612 0 014.78-.822c3.681 0 6.71 2.717 9.917 5.593 3.656 3.279 7.437 6.67 12.584 6.67 1.609 0 4.161-.765 4.161-2.821 0-1.725-1.641-3.82-3.584-4.576-6.045-2.351-10.134-5.892-13.42-8.738-2.636-2.282-4.717-4.085-6.545-4.085a2.881 2.881 0 00-3.159 2.345 1.225 1.225 0 11-2.425-.346c.386-2.7 2.578-4.449 5.585-4.449 2.742 0 5.128 2.066 8.149 4.683 3.143 2.721 7.054 6.109 12.7 8.306 2.885 1.123 5.146 4.136 5.146 6.859-.001 3.428-3.407 5.274-6.611 5.274z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11614">
            <path
              data-name="Caminho 24404"
              d="M42.105 55.387c-3.3 0-5.963-2.188-8.785-4.5-3.126-2.569-6.356-5.222-10.749-5.222h-.705c-2.3 0-5.294-.258-6.522-1.486a1.225 1.225 0 111.727-1.732c.373.373 2.178.769 4.79.769h.71c5.27 0 9.007 3.067 12.3 5.773 2.581 2.119 4.81 3.948 7.23 3.948 3.135 0 4.027-2.271 4.795-5.014l.1-.354a1.225 1.225 0 012.355.673l-.1.342c-.658 2.377-1.898 6.803-7.146 6.803z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11615">
            <path
              data-name="Caminho 24405"
              d="M1.224 74.218a1.225 1.225 0 01-.969-1.978c16.827-21.814 22.82-26.3 26.789-27.892a1.225 1.225 0 11.914 2.273c-3.473 1.4-9.249 5.709-25.763 27.115a1.226 1.226 0 01-.971.482z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11616">
            <path
              data-name="Caminho 24406"
              d="M25.346 104.601a1.225 1.225 0 01-.933-2.017 77.76 77.76 0 015.976-6.13c.647-.617 1.293-1.232 1.929-1.851-5.463-5.532-9.962-10.615-14.713-15.981-3.525-3.982-7.17-8.1-11.254-12.428a1.225 1.225 0 111.781-1.681c4.111 4.355 7.769 8.488 11.308 12.485 4.973 5.625 9.673 10.932 15.473 16.754a1.225 1.225 0 010 1.731c-.925.925-1.881 1.836-2.84 2.75a75.8 75.8 0 00-5.79 5.932 1.227 1.227 0 01-.937.436z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11617">
            <path
              data-name="Caminho 24407"
              d="M29.498 90.263a1.225 1.225 0 01-.886-2.072c7.683-8.029 10.642-8.5 15.12-9.214 5.737-.913 14.407-2.294 41.481-22.244 2.333-1.718 4.212-5.316 4.212-7.148a5.344 5.344 0 00-3.608-4.463 1.224 1.224 0 01-.762-1.457 11.843 11.843 0 00.426-2.922c0-3.3-3.024-4.883-5.56-5.626a1.225 1.225 0 01-.876-1.282c.05-.576.074-1.09.074-1.57 0-7.134-6.982-9.179-9.611-9.949a5.366 5.366 0 01-1.2-.431 1.225 1.225 0 111.259-2.1c.066.022.308.087.627.18 2.813.824 11.372 3.331 11.372 12.3 0 .26-.006.527-.018.806 4.128 1.47 6.388 4.17 6.388 7.67a12.8 12.8 0 01-.271 2.524 7.7 7.7 0 014.215 6.318c0 2.814-2.434 7.075-5.208 9.12-27.559 20.306-36.583 21.738-42.554 22.694-4.27.68-6.622 1.055-13.735 8.488a1.22 1.22 0 01-.885.378z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11618">
            <path
              data-name="Caminho 24408"
              d="M69.731 21.275a5.253 5.253 0 01-1.031-.1 1.225 1.225 0 11.478-2.4 2.833 2.833 0 00.552.053c2.153 0 7.419-2.768 7.419-7.9 0-4.078-1.6-4.078-2.76-4.078-1.655.032-11.5 1.7-12.32 5.6a1.226 1.226 0 11-2.4-.5c1.345-6.387 14.585-7.543 14.717-7.543 3.505 0 5.21 2.135 5.21 6.527.003 6.689-6.516 10.341-9.865 10.341z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11619">
            <path
              data-name="Caminho 24409"
              d="M66.958 95.981a1.217 1.217 0 01-.9-.391 178.507 178.507 0 00-16.337-15.6 1.226 1.226 0 011.551-1.9 181.034 181.034 0 0116.58 15.826 1.225 1.225 0 01-.9 2.059z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11620">
            <path
              data-name="Caminho 24410"
              d="M101.456 87.387a1.219 1.219 0 01-.79-.289c-3.027-2.562-5.49-4.542-7.663-6.289-6.981-5.612-10.167-8.174-14.761-16.443a1.225 1.225 0 112.141-1.19c4.361 7.85 7.228 10.155 14.155 15.724a368.33 368.33 0 017.71 6.327 1.225 1.225 0 01-.792 2.16z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11621">
            <path
              data-name="Caminho 24411"
              d="M72.974 109.084a1.222 1.222 0 01-.892-.385c-3.3-3.5-6.606-6.706-9.839-9.525a1.226 1.226 0 01-.033-1.816c4.279-4.023 9.121-7.808 14.247-11.817 3.971-3.1 8.077-6.316 12.393-9.985a1.225 1.225 0 111.587 1.866c-4.354 3.7-8.481 6.929-12.471 10.049-4.687 3.665-9.135 7.142-13.092 10.757 2.968 2.646 5.987 5.6 8.992 8.792a1.225 1.225 0 01-.892 2.064z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11622">
            <path
              data-name="Caminho 24412"
              d="M62.602 90.354a1.225 1.225 0 01-.654-2.261 110.317 110.317 0 0011.232-8.488 118.566 118.566 0 0110.361-7.926 1.225 1.225 0 011.341 2.051 116.551 116.551 0 00-10.143 7.765 112.421 112.421 0 01-11.486 8.671 1.22 1.22 0 01-.651.188z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11623">
            <path
              data-name="Caminho 24413"
              d="M70.957 90.354a1.223 1.223 0 01-.981-.49l-2.593-3.457a1.225 1.225 0 111.96-1.47l2.594 3.458a1.224 1.224 0 01-.98 1.959z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11624">
            <path
              data-name="Caminho 24414"
              d="M77.585 84.88a1.223 1.223 0 01-.98-.49l-2.594-3.458a1.225 1.225 0 111.96-1.469l2.593 3.457a1.225 1.225 0 01-.979 1.96z"
              fill="#480d7e"
            />
          </g>
          <g data-name="Grupo 11625">
            <path
              data-name="Caminho 24415"
              d="M84.211 81.423a1.223 1.223 0 01-.764-.268 13.489 13.489 0 01-1.7-1.718 7.393 7.393 0 00-2.116-1.889 1.226 1.226 0 011.1-2.191 9.523 9.523 0 012.863 2.467 11.3 11.3 0 001.388 1.418 1.225 1.225 0 01-.765 2.182z"
              fill="#480d7e"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LogoMicrobank;
