import styled from 'styled-components';
import totemIllustration from '../../assets/totem-illustration.svg';

export const ContainerDesktop = styled.section`
  padding: 100px;
  display: grid;
  grid-template-columns: 1fr 3fr;

  .image-complete-bench {
    background: url(${totemIllustration});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .info-complete-bench {
    background: linear-gradient(20deg, #e7e7ed 1%, #ffffff 100%);
    position: relative;
    padding: 40px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  .info-complete-bench .images {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  #phone {
    position: absolute;
    top: -100px;
    right: -120px;
  }

  #notebook {
    position: absolute;
    bottom: -100px;
    right: -100px;
    width: 50%;
  }

  #notebook img {
    width: 100%;
  }

  .info-complete-bench h2 {
    color: #040242;
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .info-complete-bench h3 {
    color: #480d7e;
    font-size: 1.4em;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 38px;
  }

  .info-complete-bench p {
    color: #52526a;
    font-size: 1.2em;
    line-height: 30px;
  }

  .info-complete-bench .button {
    position: absolute;
    bottom: -15px;
  }

  .info-complete-bench .button a {
    color: white;
    font-size: 1.4em;
    font-weight: 600;
    background-color: #480d7e;
    padding: 10px 30px;
    text-decoration: none;
  }

  .info-complete-bench .button a:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const ContainerTablet = styled.section`
  display: none;
  background: linear-gradient(20deg, #e7e7ed 1%, #ffffff 100%);
  padding: 20px 10px;
  position: relative;

  #notebook {
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 50%;
  }

  #notebook img {
    width: 100%;
  }

  .images {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  #phone img {
    width: 140%;
  }

  #totem img {
    width: 100%;
  }

  .info-complete-bench {
    position: relative;
    padding: 40px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .info-complete-bench h2 {
    color: #040242;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 36px;
  }

  .info-complete-bench h3 {
    color: #480d7e;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    line-height: 28px;
  }

  .info-complete-bench p {
    color: #52526a;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
  }

  .info-complete-bench .button {
    position: absolute;
    bottom: 30px;
  }

  .info-complete-bench .button a {
    color: white;
    font-size: 12px;
    font-weight: 500;
    background-color: #480d7e;
    padding: 10px 30px;
    text-decoration: none;
  }

  .info-complete-bench .button a:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 100px 0 50px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ContainerMobile = styled.section`
  display: none;
  padding: 100px 0 20px;

  .first {
    display: grid;
    grid-template-columns: 1fr 2fr;

    position: relative;
  }

  .first img {
    width: 80%;
  }

  .first h2 {
    color: #040242;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .first p {
    color: #480d7e;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
  }

  .first h3 {
    color: #480d7e;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    width: 100%;
    margin-top: 20px;
  }

  .second {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
  }

  .second img#mao {
    margin-top: 20px;
    width: 60%;
  }

  .second img#notebook {
    width: 90%;
    position: absolute;
    bottom: 10px;
    right: 15px;
  }

  .accordion p {
    color: #52526a;
    font-size: 12px;
    line-height: 22px;
    font-weight: 300;
    margin-bottom: 20px;
    padding: 10px 10px;
  }

  .accordion .purple {
    color: #480d7e;
    font-size: 12px;
    line-height: 22px;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;
