import styled from 'styled-components';
import headerBackground from '../../assets/header-background.png';
import headerBackgroundMobile from '../../assets/header-background-mobile.png';

export const Header = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-top: 75px;

  div.block-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  div.block-info h1 {
    color: #040242;
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 70px;
  }

  div.block-info p {
    color: #480d7e;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 32px;
  }

  div.block-info p span {
    color: #901957;
    text-decoration: underline;
  }

  div.block-image {
    background: url(${headerBackground});
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .logo-whatsapp {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 10;
    transition: 0.5s;
    cursor: pointer;
  }

  .logo-whatsapp:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 1400px) {
    margin-top: 120px;

    div.block-info h1 {
      font-size: 2.8em;
    }

    div.block-info p {
      font-size: 1.2em;
    }
  }

  @media screen and (max-width: 1000px) {
    margin-top: 75px;
    height: auto;

    div.block-info {
      justify-content: flex-start;
      margin-top: 50px;
    }

    div.block-info h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
    }

    div.block-info p {
      font-size: 12px;
      font-weight: 300;
      line-height: 22px;
    }

    div.block-image {
      background: url(${headerBackground});
      background-position: top right;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .logo-whatsapp {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const HeaderMobile = styled.div`
  display: none;
  background: url(${headerBackgroundMobile});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;

  .content {
    display: inline-block;
    margin-top: 100px;
    margin-left: 10px;
  }

  h1 {
    color: #e7e7ed;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 18px;
    width: 65%;
  }

  p {
    color: #e7e7ed;
    font-size: 11px;
    line-height: 18px;
    width: 40%;
  }

  span {
    color: #c52887;
    font-size: 12px;
    line-height: 20px;
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const Connect = styled.div`
  display: none;
  background: linear-gradient(0deg, #ffffff 1%, #e7fae6 100%);

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    border: 6px solid #e7fae6;
    background: linear-gradient(45deg, #710844 1%, #020b4e 100%);
    padding: 15px 12px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
  }

  a img {
    width: 20px;
    height: 15px;
  }

  p {
    color: #480d7e;
    font-size: 16px;
    font-weight: 600;
    display: block;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;
