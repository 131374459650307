import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -40%;
  height: 150px;
`;

export const Slide = styled.div`
  border: 2px solid #92a1fd;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 90px;
  width: 90%;
  margin: 0 auto;

  p {
    text-align: center;
  }
`;
