import * as React from 'react';

function IconPci(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={143.323}
      height={54.835}
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.743}
          y1={1.111}
          x2={0.422}
          y2={-0.713}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#225e63" />
          <stop offset={1} stopColor="#3faeb7" />
        </linearGradient>
      </defs>
      <g data-name="Grupo 12155">
        <path
          data-name="Caminho 24663"
          d="M97.847 31.143l1.433.43a2.431 2.431 0 01-.454 1 1.858 1.858 0 01-.764.6 2.769 2.769 0 01-1.17.215 3.472 3.472 0 01-1.409-.239 2.183 2.183 0 01-.931-.884 2.942 2.942 0 01-.406-1.6 2.8 2.8 0 01.693-2.006 2.586 2.586 0 011.958-.693 2.664 2.664 0 011.552.406 2.181 2.181 0 01.836 1.242l-1.433.31a.834.834 0 00-.167-.358.973.973 0 00-.334-.287.937.937 0 00-.43-.1.921.921 0 00-.836.454 1.935 1.935 0 00-.215 1.027 2.045 2.045 0 00.263 1.194.91.91 0 00.74.334.881.881 0 00.693-.263 1.629 1.629 0 00.381-.782z"
          fill="#fff"
        />
        <path
          data-name="Caminho 24664"
          d="M99.856 30.666a2.488 2.488 0 012.723-2.723 2.823 2.823 0 012.03.693 2.654 2.654 0 01.716 1.982 3.064 3.064 0 01-.31 1.5 2.215 2.215 0 01-.908.908 2.986 2.986 0 01-1.457.334 3.41 3.41 0 01-1.481-.287 2.31 2.31 0 01-.955-.908 2.706 2.706 0 01-.358-1.499zm1.624 0a1.837 1.837 0 00.287 1.146.97.97 0 00.812.358 1.025 1.025 0 00.812-.334 1.983 1.983 0 00.287-1.218 1.484 1.484 0 00-.31-1.075.984.984 0 00-.812-.334.954.954 0 00-.788.358 1.6 1.6 0 00-.288 1.099z"
          fill="#fff"
        />
        <path
          data-name="Caminho 24665"
          d="M106.161 28.038h2.126l.812 3.2.812-3.2h2.126v5.254H110.7V29.28l-1.027 4.012h-1.194l-1.027-4.012v4.012h-1.339v-5.254z"
          fill="#fff"
        />
        <path
          data-name="Caminho 24666"
          d="M113.086 28.038h2.7a1.775 1.775 0 011.314.43 1.589 1.589 0 01.43 1.194 1.636 1.636 0 01-.478 1.242 2.012 2.012 0 01-1.457.454h-.884v1.958h-1.625zm1.624 2.245h.406a1.022 1.022 0 00.669-.167.547.547 0 00.191-.406.576.576 0 00-.167-.43.842.842 0 00-.621-.167h-.454v1.17z"
          fill="#fff"
        />
        <path
          data-name="Caminho 24667"
          d="M118.388 28.038h1.624v3.965h2.532v1.29h-4.156z"
          fill="#fff"
        />
        <path
          data-name="Caminho 24668"
          d="M123.356 28.038h1.624v5.254h-1.624z"
          fill="#fff"
        />
        <path
          data-name="Caminho 24669"
          d="M129.375 32.408h-1.839l-.263.86h-1.648l1.982-5.254h1.767l1.982 5.254h-1.7zm-.334-1.122l-.573-1.887-.573 1.887z"
          fill="#fff"
        />
        <path
          data-name="Caminho 24670"
          d="M131.858 28.038h1.5l1.982 2.914v-2.914h1.529v5.254h-1.529l-1.958-2.89v2.89h-1.524z"
          fill="#fff"
        />
        <path
          data-name="Caminho 24671"
          d="M137.59 28.038h4.944v1.29h-1.644v3.965h-1.624v-3.965h-1.648v-1.29z"
          fill="#fff"
        />
        <path
          d="M99.112 242.339l4.7-1.481-1.528-3.391a38.861 38.861 0 00-3.172 4.872zm-1.6-15.5L88.293 206.3 20.8 210.121l18.7 51.014 35.991-11.345c-2.293-3.3-3.129-7.213-.478-9.123 2.961-2.149 7.428.334 10.27 3.845 2.747-4.585 10.461-15.261 12.228-17.673z"
          transform="translate(-20.8 -206.3)"
          fill="url(#prefix__a)"
        />
        <path
          d="M65.392 14.843a4.832 4.832 0 10-5.111-4.824 4.97 4.97 0 005.111 4.824zm-4.179 2.078h8.359v21.853h-8.359zM57.081 23.298c.072.024.119 0 .119-.072v-5.541a.244.244 0 00-.119-.191s-1.1-.716-4.442-.908a23.208 23.208 0 00-3.893 0c-12.18.981-12.634 9.793-12.634 10.175v2.149c0 .263 0 9.267 12.634 10.055 1.242.1 3.654 0 3.893 0a13.315 13.315 0 004.92-.884.18.18 0 00.119-.167v-5.183c0-.072-.048-.1-.1-.048 0 0-.908.716-4.872 1.122a7.068 7.068 0 01-2.078 0c-5.636-.955-5.9-5.063-5.9-5.063 0-.072-.024-.191-.024-.239v-1.576a.736.736 0 01.024-.239s.382-4.418 5.9-4.9h2.084a12.447 12.447 0 014.369 1.51zM12.897 38.702a.113.113 0 00.119.119h8.144a.113.113 0 00.119-.119v-6.353a.113.113 0 01.119-.119s13.016.931 13.016-7.786c0-6.9-8.168-7.642-10.843-7.523H13.016a.113.113 0 00-.119.119zm8.24-11.32v-5.708h2.006s2.89.119 3.129 2.1a4.779 4.779 0 010 1.17 3.09 3.09 0 01-2.89 2.436z"
          fill="#fff"
        />
        <path
          d="M65.627 49.318a4.3 4.3 0 002.054-.382c2.961-1.552 12.968-25.984 23.525-33.508a.727.727 0 00.191-.191.349.349 0 00.072-.191s0-.5-1.552-.5c-9.386-.263-19.154 19.441-24.289 27.226-.072.1-.406 0-.406 0s-3.439-4.06-6.424-5.612a2.045 2.045 0 00-.764-.119 4.332 4.332 0 00-2.269.955 2.538 2.538 0 00-.74 2.245 1.651 1.651 0 00.143.573 49.107 49.107 0 006.807 8.407c.408.309 1.052 1.097 3.652 1.097z"
          fill="#15cc0e"
        />
        <path
          data-name="Caminho 24672"
          d="M93.979 8.049h7.81a9.448 9.448 0 013.726.621 6.08 6.08 0 012.341 1.791 7.265 7.265 0 011.337 2.723 12.213 12.213 0 01.43 3.3 11.61 11.61 0 01-.621 4.227 7 7 0 01-1.72 2.532 5.976 5.976 0 01-2.364 1.361 12.061 12.061 0 01-3.129.454h-7.81zm5.254 3.845v9.29h1.29a5.465 5.465 0 002.341-.358 2.531 2.531 0 001.1-1.266 7.984 7.984 0 00.406-2.961c0-1.815-.287-3.033-.884-3.7a3.731 3.731 0 00-2.938-1z"
          fill="#fff"
        />
        <path
          data-name="Caminho 24673"
          d="M111.079 19.438l5.015-.31a3.957 3.957 0 00.669 1.863 2.769 2.769 0 002.317 1.027 2.579 2.579 0 001.743-.525 1.517 1.517 0 00.048-2.412 6.609 6.609 0 00-2.7-.979 11.622 11.622 0 01-4.944-2.078 4.126 4.126 0 01-1.481-3.3 4.53 4.53 0 01.764-2.484 4.982 4.982 0 012.293-1.839 10.534 10.534 0 014.18-.669 8.471 8.471 0 014.968 1.218 5.231 5.231 0 012.03 3.869l-4.968.287a2.719 2.719 0 00-.834-1.668 2.621 2.621 0 00-1.743-.525 2.085 2.085 0 00-1.385.382 1.186 1.186 0 00-.454.955.95.95 0 00.382.74 4.383 4.383 0 001.767.621 23.022 23.022 0 014.944 1.5 5.188 5.188 0 012.173 1.887 4.777 4.777 0 01.669 2.532 5.371 5.371 0 01-.908 3.009 5.637 5.637 0 01-2.532 2.1 10.217 10.217 0 01-4.108.716c-2.89 0-4.9-.549-6.018-1.672a6.794 6.794 0 01-1.887-4.245z"
          fill="#fff"
        />
        <path
          data-name="Caminho 24674"
          d="M127.871 19.438l5.015-.31a3.957 3.957 0 00.669 1.863 2.769 2.769 0 002.317 1.027 2.579 2.579 0 001.743-.525 1.518 1.518 0 00.048-2.412 6.608 6.608 0 00-2.7-.979 11.622 11.622 0 01-4.944-2.078 4.2 4.2 0 01-1.481-3.3 4.531 4.531 0 01.764-2.484 4.983 4.983 0 012.293-1.839 10.534 10.534 0 014.18-.669 8.471 8.471 0 014.968 1.218 5.231 5.231 0 012.03 3.869l-4.968.287a2.719 2.719 0 00-.836-1.672 2.621 2.621 0 00-1.743-.525 2.085 2.085 0 00-1.385.382 1.186 1.186 0 00-.454.955.95.95 0 00.382.74 4.384 4.384 0 001.767.621 23.022 23.022 0 014.944 1.5 5.188 5.188 0 012.173 1.887 4.778 4.778 0 01.669 2.532 5.371 5.371 0 01-.908 3.009 5.637 5.637 0 01-2.532 2.1 10.217 10.217 0 01-4.108.716c-2.89 0-4.9-.549-6.018-1.672a6.551 6.551 0 01-1.885-4.241z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default IconPci;
