import styled from 'styled-components';

export const Container = styled.section`
  padding: 50px 0;
  position: relative;

  .texts {
    width: 40%;
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;
  }

  .image-tablet {
    display: none;
  }

  .image img {
    width: 80%;
  }

  h2 {
    color: #040242;
    font-size: 2em;
    margin-bottom: 10px;
  }

  h3 {
    color: #480d7e;
    font-size: 1.4em;
    margin-bottom: 20px;
  }

  p {
    color: #52526a;
    font-size: 1.2em;
  }

  span {
    font-weight: 500;
  }

  @media screen and (max-width: 1950px) {
    .image {
      position: absolute;
      top: 0;
      right: 0;
    }
    .image img {
      width: 85%;
    }
  }

  @media screen and (max-width: 1750px) {
    .image {
      position: absolute;
      top: 50px;
      right: -300px;
    }
    .image img {
      width: 70%;
    }
  }

  @media screen and (max-width: 1650px) {
    .image {
      top: 80px;
      right: -350px;
    }
    .image img {
      width: 70%;
    }
  }

  @media screen and (max-width: 1500px) {
    .image {
      top: 100px;
      right: -600px;
    }
    .image img {
      width: 60%;
    }
  }

  @media screen and (max-width: 1450px) {
    .image {
      top: 150px;
    }
  }

  @media screen and (max-width: 1350px) {
    .image {
      top: 200px;
      right: -650px;
    }

    .image img {
      width: 55%;
    }
  }

  @media screen and (max-width: 1250px) {
    padding: 80px 0 0;

    .texts {
      width: 100%;
    }

    .image {
      display: none;
    }

    .image-tablet {
      display: block;
      margin-top: 50px;
    }

    .image-tablet img {
      width: 100%;
    }
  }

  @media screen and (max-width: 1000px) {
    h2 {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 20px;
    }

    p {
      font-size: 12px;
      line-height: 22px;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ContainerMobile = styled.section`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    padding: 90px 0px 100px;
    position: relative;
    .texts {
      padding: 0 10px;
    }

    .texts h2 {
      color: #040242;
      font-size: 18px;
      line-height: 36px;
      margin-bottom: 10px;
    }

    .texts h3 {
      color: #480d7e;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    .texts p {
      color: #52526a;
      font-size: 12px;
      line-height: 20px;
      font-weight: 300;
      margin-bottom: 10px;
    }

    .texts span {
      color: #52526a;
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
    }

    .block-image {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .block-image img {
      width: 100%;
    }

    .background {
      background: linear-gradient(0deg, #020b4e 1%, #313b83 100%);
      height: 250px;
      width: 100%;
      position: absolute;
      bottom: 50px;
      z-index: -1;
    }
  }
`;
