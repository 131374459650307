// Dependencies
import React from 'react';

// Style
import { Container, Navigation } from './style';

// Image
import LogoMicrobank from '../Icons/LogoMicrobank';

function Nav() {
  return (
    <Container>
      <Navigation>
        <div className="container">
          <div className="content">
            <div className="img-logo">
              <LogoMicrobank />
            </div>

            <nav>
              <ul>
                <li>
                  <a href="#init">Início</a>
                </li>

                <li>
                  <a href="#whyHaveBank">Motivos</a>
                </li>

                <li>
                  <a href="#completeBank">Possibilidades</a>
                </li>

                <li>
                  <a href="#bankServices">Serviços</a>
                </li>

                <li>
                  <a href="#build">Customização</a>
                </li>
              </ul>
            </nav>

            <div id="block-button">
              <p>Banking as a service!</p>
              <a href="#join">Entre em Contato</a>
            </div>
          </div>
        </div>
      </Navigation>
    </Container>
  );
}

export default Nav;
