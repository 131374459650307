import styled from 'styled-components';

export const Container = styled.section`
  background: linear-gradient(#ffffff 0%, #e7e7ed 100%);
  padding: 200px 0;
  margin: 50px 0;

  .grid-info {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 40px;
  }

  .go-to-market-info h2 {
    color: #040242;
    font-size: 2em;
    margin-bottom: 10px;
  }

  .go-to-market-info h3 {
    color: #480d7e;
    font-size: 1.4em;
    margin-bottom: 20px;
    line-height: 38px;
  }

  .go-to-market-info p {
    color: #52526a;
    font-size: 1.2em;
    line-height: 30px;
  }

  .go-to-market-info p span {
    font-weight: 500;
  }

  /* Sessão de itens */
  .go-to-market-items h3 {
    color: #480d7e;
    font-size: 1.4em;
    margin-bottom: 10px;
  }

  .go-to-market-items div.grid-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .go-to-market-items div.item {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  div.item .image {
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 6px 10px rgba(72, 13, 126, 0.2);
    margin-right: 10px;
  }

  div.item .image img {
    width: 80px;
    height: 80px;
  }

  .go-to-market-items div.item p {
    color: #040242;
    font-size: 1.2em;
  }

  @media screen and (max-width: 1000px) {
    padding: 100px 0;

    .grid-info {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 300px;
    }

    .go-to-market-info h2 {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 10px;
    }

    .go-to-market-info h3 {
      font-size: 16px;
      line-height: 28px;
    }

    .go-to-market-info p {
      font-size: 12px;
      line-height: 22px;
      margin-bottom: 20px;
    }

    /* Sessão de itens */
    .go-to-market-items div.grid-items {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 1fr 1fr;
    }

    .go-to-market-items h3 {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }

    .go-to-market-items div.item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 0;
    }

    div.item .image {
      border-radius: 5px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 6px 10px rgba(72, 13, 126, 0.2);
      margin-right: 0px;
      margin-bottom: 10px;
    }

    div.item .image img {
      width: 80px;
      height: 80px;
    }

    .go-to-market-items div.item p {
      color: #040242;
      font-size: 16px;
      line-height: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 10px 10px 100px;
    height: 900px;

    .go-to-market-info h2 {
      font-size: 18px;
    }

    .go-to-market-info h3 {
      font-size: 12px;
      line-height: 20px;
    }

    .go-to-market-info p {
      line-height: 20px;
    }

    /* Sessão de itens */
    .go-to-market-items div.grid-items {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
    }

    .go-to-market-items h3 {
      font-size: 16px;
    }

    .go-to-market-items div.item {
      flex-direction: row;
      margin: 10px 20px;
    }

    .go-to-market-items div.item p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-left: 10px;
    }
  }
`;
