// Dependencies
import React from 'react';

// Style
import { Container } from './style';

// Image
import BigLogo from '../Icons/IconMicrobank';
import IconHexalab from '../Icons/IconHexalab';
import LogoMicrobank from '../Icons/LogoMicrobank';
import IconVisa from '../Icons/IconVisa';
import IconPci from '../Icons/IconPci';
import IconFacebook from '../Icons/IconFacebook';
import IconLinkedin from '../Icons/IconLinkedin';
import Instagram from '../Icons/Instagram';

function Footer() {
  return (
    <Container>
      <div className="container" id="content">
        <div className="block-big-image">
          <BigLogo />
        </div>

        <div className="block-icons">
          <div className="icon">
            <IconVisa />
          </div>

          <div className="icon">
            <IconPci />
          </div>

          <div className="icon">
            <IconHexalab />
          </div>
        </div>

        <div className="block-info">
          <div>
            <LogoMicrobank />
          </div>

          <div className="text-info">
            <p>contato@microbank.com.br</p>
            <p>Teófilo David Muzel, 639 - Centro - Itapeva/SP</p>
          </div>
        </div>

        <div className="block-social-media">
          <a href="/#">
            <IconFacebook />
          </a>

          <a href="/#">
            <IconLinkedin />
          </a>

          <a href="/#">
            <Instagram />
          </a>
        </div>
      </div>
    </Container>
  );
}

export default Footer;
