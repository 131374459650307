import * as React from 'react';

function IconLinkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.998"
      height="49.998"
      viewBox="0 0 49.998 49.998"
    >
      <defs>
        <filter
          id="Caminho_48697"
          x="0"
          y="0"
          width="49.998"
          height="49.998"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood floodColor="#e0beff" floodOpacity="0.502" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Caminho_48697)">
        <path
          id="Caminho_48697-2"
          data-name="Caminho 48697"
          d="M22325.84,22242.16a7.861,7.861,0,0,1-7.84-7.834V22210a7.857,7.857,0,0,1,7.834-7.84h24.33a7.858,7.858,0,0,1,7.834,7.84v24.324a7.857,7.857,0,0,1-7.834,7.834Zm20.693-16.184v9.725h6.043v-10.42c0-5.584-2.984-8.182-6.957-8.182a5.993,5.993,0,0,0-5.443,3v-2.576h-6.043c.078,1.707,0,18.176,0,18.176h6.043v-10.15a4.2,4.2,0,0,1,.2-1.477,3.308,3.308,0,0,1,3.1-2.205C22345.658,22221.869,22346.533,22223.535,22346.533,22225.977Zm-21.783,9.725h6.039v-18.176h-6.039Zm-.357-23.8a3.126,3.126,0,0,0,3.338,3.137h.039a3.149,3.149,0,1,0-3.377-3.137Z"
          transform="translate(-22313 -22197.16)"
          fill="none"
          stroke="#92a1fd"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default IconLinkedin;
