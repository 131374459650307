import styled from 'styled-components';
import ReactSelect from 'react-select';

export const Content = styled.div`
  margin: 10px;
`;

export const Label = styled.label`
  color: #480d7e;
  font-size: 13px;
`;

export const Container = styled(ReactSelect)`
  /* Classe do próprio input do react-select */
  .react-select__control {
    border: 1px solid #480d7e;
    border-radius: 5px;
    color: #480d7e;
    padding: 1px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 10 0%;
    outline: none;
  }
`;
