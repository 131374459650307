import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { Content, Container, Label } from './style';

const InputMask = ({ name, label, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);
  return (
    <Content>
      <Label htmlFor={fieldName}>{label}</Label>
      <Container ref={inputRef} defaultValue={defaultValue} {...rest} />
    </Content>
  );
};
export default InputMask;
