import React, { useState } from 'react';
import { Container, FormRegister } from './style';

import SimpleInput from '../../components/SimpleInput';
import InputMask from '../../components/InputMask';
import InputSelect from '../../components/InputSelect';

// Importação de imagens
import imageContact from '../../assets/contact-image.png';
import IconJoin from '../../components/Icons/IconJoin';

function Join() {
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  function handleSubmit(data, { reset }) {
    console.log(data);
    reset();
  }

  return (
    <Container id="join">
      <div>
        <img src={imageContact} alt="Imagem de dois amigos" />

        <div id="block-join">
          <div id="block-image-join">
            <IconJoin />
          </div>

          <div>
            <p>Junte-se a nós e ao futuro</p>
            <p>Tenha o seu próprio banco!</p>
          </div>
        </div>
      </div>

      <div id="form" className="container">
        <div className="teste">
          <h2>
            Pronto para ter seu banco?
            <br />
            Então fale conosco!
          </h2>
          <p>
            Nossa plataforma de banco White-Label atende a diversos tipos de
            seguimentos: Fintechs, Fundos, Recebíveis, E-commerce, Startups,
            Indústrias, Varejistas, FIDCS, e vários outros. Fale conosco e com
            certeza teremos uma solução para o seu negócio. Entre em contato
            para mais esclarecimentos.
          </p>
        </div>

        <FormRegister onSubmit={handleSubmit}>
          <div className="inline">
            <SimpleInput name="name" label="Nome" type="text" />

            <SimpleInput name="company" label="Empresa" type="text" />
          </div>

          <div className="inline-grid">
            <SimpleInput
              name="internalPosition"
              label="Cargo Interno"
              type="text"
            />

            <InputSelect
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              name="state"
              label="Estado"
            />

            <InputSelect
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              name="city"
              label="Cidade"
            />
          </div>

          <div className="inline">
            <SimpleInput name="email" label="E-Mail" type="email" />

            <InputMask mask="(99) 99999-9999" name="phone" label="Telefone" />
          </div>

          <button type="submit" id="send-button">
            Enviar
          </button>
        </FormRegister>
      </div>
    </Container>
  );
}

export default Join;
