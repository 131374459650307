import * as React from 'react';

function IconVisa(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={102.982}
      height={31.562}
      {...props}
    >
      <path
        d="M74.857 1.293A21.415 21.415 0 0067.391 0c-8.239 0-14.051 4.154-14.1 10.1-.051 4.377 4.136 6.84 7.3 8.307 3.247 1.5 4.334 2.446 4.325 3.8-.02 2.048-2.595 2.972-4.986 2.972a17.65 17.65 0 01-7.858-1.611l-1.024-.478-1.164 6.857a27.95 27.95 0 009.331 1.611c8.756 0 14.483-4.125 14.552-10.467.069-3.49-2.188-6.136-6.954-8.316-2.892-1.416-4.694-2.366-4.694-3.8 0-1.27 1.539-2.626 4.757-2.626a15.276 15.276 0 016.245 1.173l.772.346 1.164-6.625-.2.043zm21.38-.729H89.8c-2.005 0-3.5.546-4.38 2.535L73.043 31.135H81.8l1.751-4.591 10.684.009c.26 1.073 1.03 4.583 1.03 4.583h7.718L96.237.561zM41.45.309h8.339l-5.215 30.592h-8.341l5.218-30.6zM20.256 17.167l.867 4.248L29.281.567h8.836L24.985 31.095h-8.817L8.959 5.238a1.708 1.708 0 00-.772-1.02A35.235 35.235 0 000 1.187L.109.543h13.433c1.819.069 3.287.644 3.79 2.592l2.926 14.046v-.014zm65.686 3.121l3.327-8.556c-.043.086.687-1.768 1.107-2.918l.572 2.637 1.925 8.825h-6.928v.009z"
        fill="#fff"
      />
    </svg>
  );
}

export default IconVisa;
