import styled from 'styled-components';
import surprise from '../../assets/surprise.svg';

export const Container = styled.section`
  padding: 200px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .info h2 {
    color: #040242;
    font-size: 2em;
    margin-bottom: 20px;
  }

  .info h3 {
    color: #480d7e;
    font-size: 1.4em;
    margin-bottom: 20px;
  }

  .info p {
    color: #52526a;
    font-size: 1.2em;
    line-height: 30px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-top: 50px;
  }

  .item {
    margin-bottom: 20px;
    display: flex;
  }

  .image {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(72, 13, 126, 0.3);
    margin-right: 10px;
  }

  .image img {
    width: 100px;
    height: 100px;
  }

  .item h4 {
    color: #020b4e;
    font-size: 1.4em;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .item p {
    color: #52526a;
    font-size: 1em;
    line-height: 28px;
  }

  .image-background {
    background: url(${surprise});
    background-repeat: no-repeat;
    position: relative;
  }

  .image-background a {
    background: #480d7e;
    font-size: 1.4em;
    color: white;
    padding: 10px 30px;
    text-decoration: none;

    position: absolute;
    bottom: -15px;
    right: 350px;
  }

  .image-background a:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const ContainerMobile = styled.section`
  padding: 100px 0px 250px;

  display: none;

  h2 {
    color: #040242;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  h3 {
    color: #480d7e;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  }

  p {
    color: #52526a;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 22px;
  }

  .img img {
    width: 100%;
  }

  .background {
    background: #e7e7ed;
    position: relative;
    height: 550px;
    display: flex;
    justify-content: center;
    margin-top: -10px;
  }

  .block-items {
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    width: 90%;
    margin: -70px auto 0px auto;
    padding: 50px;
    box-shadow: 0px 3px 6px rgba(72, 13, 126, 0.2);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
  }

  .item {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
  }

  .image {
    width: 100px;
    height: 100px;
    box-shadow: 0px 4px 10px rgba(72, 13, 126, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    margin-right: 10px;
  }

  .image img {
    width: 60px;
    height: 60px;
  }

  .item h4 {
    font-size: 16px;
    color: #020b4e;
    font-weight: 500;
  }

  .item p {
    color: #52526a;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }

  @media screen and (max-width: 1000px) {
    display: block;
  }

  @media screen and (max-width: 768px) {
    padding: 90px 10px 100px;

    h2 {
      font-size: 18px;
    }

    h3 {
      line-height: 20px;
    }

    .block-items {
      padding: 50px 10px;
    }

    .image {
      width: 70px;
      height: 70px;
    }

    .image img {
      width: 50px;
      height: 50px;
    }

    .item h4 {
      font-size: 14px;
    }

    .item p {
      font-size: 10px;
      margin-bottom: 0;
    }
  }
`;
