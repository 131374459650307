import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 125px;

  @media screen and (max-width: 1250px) {
    height: 50px;
  }
`;

export const Navigation = styled.div`
  background: linear-gradient(45deg, #710844 1%, #020b4e 100%);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.16);
  height: 125px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 20px 0px;
  z-index: 10;

  .content {
    align-items: center;
    display: flex;
    justify-content: space-between;

    ul {
      list-style: none;
      display: flex;
      width: 100%;
      height: 100%;
    }

    li {
      margin-left: 50px;
    }

    a {
      color: #e7e7ed;
      font-size: 18px;
      font-weight: 400;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    div#block-button {
      display: flex;
      flex-direction: column;
    }

    p {
      color: #ffffff;
      font-weight: 300;
      margin-bottom: 20px;
    }

    div#block-button a {
      border: 2px solid #ffffff;
      color: #ffffff;
      padding: 10px 20px;
      transition: 0.3s;
    }

    div#block-button a:hover {
      border: 2px solid #ff9d00;
      color: #ff9d00;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 1250px) {
    height: auto;
    .img-logo {
      margin-left: 15px;
    }

    a {
      text-align: center;
      width: 100%;
    }

    .content {
      ul {
        display: none;
      }

      div#block-button {
        display: none;
      }
    }
  }
`;
